const initialState = {
  permission: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PERMISSION":
      return {
        permission: action.permission,
      };
    default:
      return state;
  }
}
