import {hideLoading, showLoading, showMessage} from "@actions/global";
import {classifyVenderOfShipping} from "@shared/classifyVenderOfShipping";
import errorHandler from "@shared/errorHandler";
import {getCartItems} from "@storage/checkoutStorage";
import {checkCVSConstrain, processItems} from "../components/CheckoutPage/utils/checkoutUtils";
import API from "../shared/api";
import {UPDATE_PARTIAL_ITEM} from "./cartActionType";
import {
  UPDATE_CITY_REGION_FIELD,
  UPDATE_CVS_CITY_REGION_FIELD,
  UPDATE_CVS_FIELDS,
  UPDATE_SHIPPING_SUCCESS,
  UPDATE_TOTAL_DATA,
} from "./checkoutActionType";
import {updateShippingItems} from "./utils/cartUtils";

/**
 * Update shipping fields
 */
export function updateShippingAddress(name, value) {
  // name: input field name
  // value: current input value
  return {
    type: "UPDATE_FIELD",
    name,
    value,
  };
}

/**
 * Update city and region
 */
export function updateCityAndRegion(city, region) {
  return {
    type: UPDATE_CITY_REGION_FIELD,
    city,
    region,
  };
}

export function udpateCVSFields(name, value) {
  // name: input field name
  // value: current input value
  return {
    type: UPDATE_CVS_FIELDS,
    name,
    value,
  };
}

export function updateCVSRegionAndName(data) {
  return {
    type: UPDATE_CVS_CITY_REGION_FIELD,
    region: data.region,
    street: data.street,
    postcode: data.postcode,
    city: data.city,
  };
}

export function sendShippingInfomation(data) {
  /**
   * Data has been validated by form
   */
  const request = new API("/carts/mine/shipping-information", "default");

  return (...args) => {
    const [dispatch, getState] = args;
    const {token, addresses} = getState().auth;

    dispatch(showLoading());

    // reformat shippInfo
    const formattedShippingInfo = {
      firstname: data.firstname,
      lastname: data.lastname,
      telephone: data.telephone,
      region: data.region,
      region_code: data.region,
      country_id: "TW",
      city: data.city,
      postcode: data.postcode,
      street: [data.street],
    };

    formattedShippingInfo.save_in_address_book = checkNeedToSaveInAddress(formattedShippingInfo, addresses);

    const payload = {
      addressInformation: {
        shipping_address: formattedShippingInfo,
        billing_address: formattedShippingInfo,
        shipping_carrier_code: "flatrate",
        shipping_method_code: "flatrate",
      },
    };

    return request
      .postByUserToken(token, payload, 90000)
      .then((res) => {
        let discountTitle = "目前無符合條件的優惠";

        try {
          const discountItem = res.data.totals.total_segments.find((obj) => obj.code === "discount");
          if (discountItem) {
            // Eslint 的規則
            // var foo = array[someIndex];
            const someIndex = 1;
            discountTitle = discountItem.title.split("Discount")[someIndex];
          }
        } catch (error) {
          console.error(error);
        }

        const totalData = {
          grandTotal: res.data.totals.grand_total,
          subtotal: res.data.totals.subtotal,
          discountAmount: res.data.totals.discount_amount,
          subtotalWithDiscount: res.data.totals.base_subtotal_with_discount,
          shippingAmount: res.data.totals.shipping_amount,
          discountTitle,
        };

        dispatch({
          type: UPDATE_TOTAL_DATA,
          methods: res.data.payment_methods,
          totalData,
        });

        // update checkout state
        dispatch({
          type: UPDATE_SHIPPING_SUCCESS,
          shippingInfo: formattedShippingInfo,
        });

        // retrive current and cached items from state
        const {items} = getState().cart;
        let cachedItems = {...items};

        // 如果目前購物車商品為 0, 代表使用者可能從超商取貨頁面返回, 故從 Local 端拿取購物車資料
        if (Object.keys(items).length === 0) {
          cachedItems = getCartItems();
        }

        // update items data by item keys
        const updatedItems = updateShippingItems(cachedItems, res.data.totals.items);

        // update cart items to cart store
        dispatch({
          type: UPDATE_PARTIAL_ITEM,
          items: updatedItems,
        });

        // 如果購物車商品為 0, 代表從超取頁面返回, 需重新計算可否超取
        if (Object.keys(items).length === 0) {
          // recompute if user can use CVS
          const {lmsItems, transferItems, preorderItems} = classifyVenderOfShipping(updatedItems);
          const cvsConstrain = getState().appConfig.cvs_constrain;
          const freeShippingThresholds = getState().appConfig.shipping_threshold;
          const canCVS =
            transferItems.length > 0
              ? false
              : checkCVSConstrain({
                  constrains: {
                    sedge: cvsConstrain.s_edge,
                    medge: cvsConstrain.m_edge,
                    ledge: cvsConstrain.l_edge,
                    volumn: cvsConstrain.volumn,
                  },
                  itemData: processItems({
                    lmsItems,
                    transferItems,
                    preorderItems,
                    freeShippingThresholds,
                  }),
                });

          if (canCVS) {
            // redirect to payment
            dispatch(modifyCanGoPayment(true));
          } else {
            dispatch(showMessage("error", "抱歉，購物車商品可能因增加贈品而超出可超取限制", true));
          }
        } else {
          dispatch(modifyCanGoPayment(true));
        }
      })
      .catch((error) => {
        errorHandler(error, true);
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}

function checkNeedToSaveInAddress(shippingAddress, allAddresses) {
  let save = 1;
  const {length} = allAddresses;
  if (length > 0) {
    for (let i = length - 1; i >= 0; i--) {
      if (
        shippingAddress.postcode === allAddresses[i].postcode &&
        shippingAddress.city === allAddresses[i].city &&
        shippingAddress.region === allAddresses[i].region.region &&
        shippingAddress.street[0] === allAddresses[i].street[0] &&
        shippingAddress.firstname === allAddresses[i].firstname &&
        shippingAddress.lastname === allAddresses[i].lastname
      ) {
        save = 0;
        break;
      }
    }
  }
  return save;
}

export function modifyCanGoPayment(state) {
  return {
    type: "MODIFY_CAN_GO_PAYMENT",
    state,
  };
}

export function disableRedirect() {
  return {
    type: "DISABLE_REDIRECT",
  };
}

export function placeOrderSuccess() {
  return {
    type: "PLACE_ORDER_SUCCESS",
  };
}

export function resetCheckout() {
  return {
    type: "RESET_CHECKOUT",
  };
}
