export const UPDATE_FIELD = "UPDATE_FIELD";
export const UPDATE_CITY_REGION_FIELD = "UPDATE_CITY_REGION_FIELD";
export const UPDATE_CVS_FIELDS = "UPDATE_CVS_FIELDS";
export const UPDATE_CVS_CITY_REGION_FIELD = "UPDATE_CVS_CITY_REGION_FIELD";
export const UPDATE_TOTAL_DATA = "UPDATE_TOTAL_DATA";
export const UPDATE_SHIPPING_SUCCESS = "UPDATE_SHIPPING_SUCCESS";
export const MODIFY_CAN_GO_PAYMENT = "MODIFY_CAN_GO_PAYMENT";
export const TAPPAYSDK_LOADED = "TAPPAYSDK_LOADED";
export const DISABLE_REDIRECT = "DISABLE_REDIRECT";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const RESET_CHECKOUT = "RESET_CHECKOUT";
