export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const VISIT_EVENT_SENT = "VISIT_EVENT_SENT";

export function showMessage(messageType, message, isShow, showActionButton) {
  if (!isShow) {
    // Only report to Sentry
    return {
      type: "SHOW_MESSAGE",
    };
  }
  return {
    type: SHOW_MESSAGE,
    messgeType: messageType,
    message,
    showActionButton,
  };
}

export function clearMessage() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function showLoading() {
  return {
    type: SHOW_LOADING,
  };
}

export function hideLoading() {
  return {
    type: HIDE_LOADING,
  };
}

export function visitEventSent() {
  return {
    type: VISIT_EVENT_SENT,
  };
}
