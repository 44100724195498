import {CMS_ERROR, IS_CMS_READY} from "../actions/homepage";

const initialState = {
  default: {
    isReady: false,
    main_banner: [],
    promo_category: [],
    banner_products: [],
    promo_banners: [],
    promo_tabs: [],
    promo_category_list: [],
    editor_choice: [],
    limited_time: [],
    top_activities_group: [],
  },
  morningshop: {
    isReady: false,
    main_banner: [],
    promo_category: [],
    banner_products: [],
    promo_banners: [],
    promo_tabs: [],
    promo_category_list: [],
    editor_choice: [],
    limited_time: [],
    top_activities_group: [],
  },
  proteinshop: {
    isReady: false,
    main_banner: [],
    promo_category: [],
    banner_products: [],
    promo_banners: [],
    promo_tabs: [],
    promo_category_list: [],
    editor_choice: [],
    limited_time: [],
    top_activities_group: [],
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case IS_CMS_READY:
      return {
        ...state,
        [action.key]: {
          isReady: true,
          main_banner: action.data.main_banner,
          promo_category: action.data.promo_category,
          banner_products: action.data.banner_products,
          promo_banners: action.data.promotion_banner,
          promo_tabs: action.data.tabs,
          promo_category_list: action.data.promo_category_list,
          editor_choice: action.data.editor_choice,
          limited_time: action.data.limited_time,
          top_activities_group: action.data.top_activities_group,
        },
      };
    case CMS_ERROR:
      return {
        ...state,
        isCMSReady: false,
      };
    default:
      return state;
  }
}
