import {hideLoading, showLoading, showMessage} from "@actions/global";
import API from "@shared/api";
import iterableAnalytics from "@shared/iterableAnalytics";
import gtagTrack from "@shared/gtagTrack";
import FBPixel from "../components/FBPixel";
import GA from "../components/GA";
import {normalizeCouponList} from "../components/CheckoutPage/utils/checkoutUtils";
import * as ACTION from "./cartActionType";
import {normalizeCheckoutData} from "./utils/cartUtils";

const UNAUTHORIZE = "unauthorized";
const ERROR = "error";
const DEFAULT_ERROR_MESSSAGE = "系統繁忙中，暫時無法取得購物車，請稍後再試";
/**
 * Fetch Checkout Config
 * @param {*} cartId
 * @param {*} token
 * @returns {Promise}
 */
export function fetchCheckoutConfig(cartId, token) {
  const request = new API(`/checkout/configData?quoteId=${cartId}`, "default");
  return request
    .getByUserToken(token, 60000)
    .then((response) => {
      const normalizedData = normalizeCheckoutData(response.data);
      return {error: false, normalizedData, totalQty: response.data.totals_data.items_qty};
    })
    .catch((error) => {
      if (error.status === 401) {
        return {error: UNAUTHORIZE, normalizedData: null, totalQty: 0};
      }

      return {error: ERROR, normalizedData: null, totalQty: 0};
    });
}

/**
 * Fetch user CartId
 * @param {*} token
 * @returns {Promise}
 */
export function fetchUserCardId(token) {
  const request = new API("/carts/mine", "default", "V1", 3);
  return request
    .postByUserToken(token, undefined, 60000)
    .then((res) => {
      return {
        type: "success",
        cartId: `${res.data}`,
      };
    })
    .catch((error) => {
      if (error.status === 401) {
        return {
          type: UNAUTHORIZE,
        };
      }
      return {
        type: ERROR,
      };
    });
}

/**
 * Fetch add to cart api
 * @param {Object} param0
 */
function addItemToServerCart({token, userCartId, storeName, item}) {
  const request = new API("/carts/mine/items", storeName);
  const data = {
    cartItem: {
      sku: item.sku,
      qty: item.qty,
      quote_id: userCartId,
    },
  };
  return request
    .postByUserToken(token, data, 60000)
    .then((res) => ({type: "success", result: res.data}))
    .catch((error) => {
      if (error.status === 401) {
        return {
          type: UNAUTHORIZE,
        };
      }
      return {
        type: ERROR,
        errorMessage: error.message,
      };
    });
}

function updateGuestCart(newItems) {
  const subtotalWithDiscount = Object.keys(newItems).reduce((accumulator, currentValue) => {
    const item = newItems[currentValue];
    return accumulator + item.row_total_with_discount;
  }, 0);
  return {
    type: ACTION.UPDATE_GUEST_CART,
    newItems,
    subtotalWithDiscount,
  };
}

function handleCartFetchError(errorMessage) {
  return {
    type: ACTION.CART_FETCH_ERROR,
    errorMessage,
  };
}

/**
 * Initialize Member Cart
 * 1. fetch user cart id
 * 2. fetch checkout config
 */
export function initializeMemberCart() {
  return async function thunk(...args) {
    const [dispatch, getState] = args;
    const {token} = getState().auth;
    const {cartId} = getState().cart;
    const {customerGroupId} = getState().vip;
    let userCartId = cartId;

    dispatch({
      type: ACTION.CART_INITIALIZE,
    });
    if (!userCartId) {
      const serverResponse = await fetchUserCardId(token);

      if (serverResponse.type === ERROR) {
        dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));
        return;
      }
      if (serverResponse.type === UNAUTHORIZE) {
        dispatch(handleCartFetchError("您已被登出，請重新登入", true));
        return;
      }
      userCartId = serverResponse.cartId;
      dispatch({
        type: ACTION.UPDATE_CART_ID,
        cartId: userCartId,
      });
    }

    // await mergeCart(userCartId, token, customerGroupId);
    const {error, normalizedData, totalQty} = await fetchCheckoutConfig(userCartId, token, dispatch);
    if (error) {
      dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));
      return;
    }

    // iterable
    iterableAnalytics.viewCart(normalizedData.items, normalizedData.totalData.subtotal);

    dispatch({
      type: ACTION.GET_CHECKOUT_DATA,
      data: normalizedData,
      itemsQty: totalQty,
    });

    dispatch({
      type: ACTION.CART_INITIALIZE_END,
    });
  };
}

export function addItemToCart(item, storeName) {
  return async function thunk(...args) {
    const [dispatch, getState] = args;
    const {cartId} = getState().cart;
    const {token} = getState().auth;
    let userCartId = cartId;

    if (window.location.href.indexOf("cartInfo") !== -1) {
      // 加價購只秀 loading
      dispatch(showLoading());
    } else {
      // 商品詳情頁加入購物車, 無論成功與否, 先提示加入購物車訊息, 加快前端體感速度
      dispatch(showMessage("cart-add", item.name, true));
    }

    dispatch({
      type: ACTION.UPDATE_ITEM_TO_CART,
      addedQty: item.qty,
    });

    dispatch({
      type: ACTION.CART_FETCH_START,
    });

    // if there is no user cart id,fetch it first
    if (!userCartId) {
      const serverResponse = await fetchUserCardId(token, dispatch);
      if (serverResponse.type === ERROR) {
        dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));
        return;
      }
      if (serverResponse.type === UNAUTHORIZE) {
        dispatch(handleCartFetchError("您已被登出，請重新登入"));
        // 因 addItemToCart 有可能在任何頁面觸發, 因此多呼叫一個顯示 Toast 的 action
        dispatch(showMessage("error", "您已被登出，請重新登入", true));
        return;
      }
      userCartId = serverResponse.cartId;
      dispatch({
        type: ACTION.UPDATE_CART_ID,
        cartId: userCartId,
      });
    }

    // 有 user cart id 後才能加入購物車
    const serverResponse = await addItemToServerCart({token, userCartId, storeName, item});
    if (serverResponse.type === ERROR) {
      dispatch(showMessage("cart-error", serverResponse.errorMessage, true));
      dispatch({
        type: ACTION.UPDATE_ITEM_TO_CART_FAILED,
        subQty: item.qty,
      });
      dispatch({
        type: ACTION.CART_FETCH_END,
      });
      return;
    }
    if (serverResponse.type === UNAUTHORIZE) {
      dispatch(handleCartFetchError("您已被登出，請重新登入"));
      // 因 addItemToCart 有可能在任何頁面觸發, 因此多呼叫一個顯示 Toast 的 action
      dispatch(showMessage("error", "您已被登出，請重新登入", true));
      return;
    }
    const {error, normalizedData, totalQty} = await fetchCheckoutConfig(userCartId, token, dispatch);
    if (error) {
      dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));

      return;
    }

    // tracking
    const eventId = serverResponse.result.item_id;
    iterableAnalytics.addToCart(normalizedData.items, normalizedData.totalData.subtotal);
    GA.addToCart(item, "add");
    gtagTrack.addToCart(item);
    FBPixel.addToCart(item, eventId);

    dispatch({
      type: ACTION.GET_CHECKOUT_DATA,
      data: normalizedData,
      itemsQty: totalQty,
    });
    dispatch({
      type: ACTION.CART_FETCH_END,
    });
  };
}

export const directCheckout = (item, storeName) => {
  return async function thunk(...args) {
    const [dispatch] = args;
    dispatch(showLoading());
    await dispatch(addItemToCart(item, storeName));
    dispatch(hideLoading());
  };
};

function modifyCartItemToServer(token, cartId, item) {
  const request = new API(`/carts/mine/items/${item.item_id}`, "default");
  return request
    .putByUserToken(
      token,
      {
        cartItem: {
          sku: item.sku,
          qty: item.qty,
          quote_id: cartId,
        },
      },
      60000,
    )
    .then((response) => ({
      type: "success",
      result: response.data,
    }))
    .catch(() => ({
      type: ERROR,
    }));
}

export const modifyItemToCart = (item, trackingItemData) => {
  return async function thunk(...args) {
    const [dispatch, getState] = args;
    const {cartId} = getState().cart;
    const {token} = getState().auth;
    let userCartId = cartId;

    dispatch({
      type: ACTION.CART_FETCH_START,
    });

    // if there is no user cart id,fetch it first
    if (!userCartId) {
      const serverResponse = await fetchUserCardId(token, dispatch);
      if (serverResponse.type === ERROR) {
        dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));
        return;
      }
      if (serverResponse.type === UNAUTHORIZE) {
        dispatch(handleCartFetchError("您已被登出，請重新登入"));
        return;
      }
      userCartId = serverResponse.cartId;
      dispatch({
        type: ACTION.UPDATE_CART_ID,
        cartId: userCartId,
      });
    }

    const serverResponse = await modifyCartItemToServer(token, userCartId, item);
    if (serverResponse.type === ERROR) {
      dispatch(handleCartFetchError("目前系統繁忙中，暫時無法同步購物車，請稍後再試"));
      return;
    }
    const {error, normalizedData, totalQty} = await fetchCheckoutConfig(userCartId, token, dispatch);
    if (error) {
      dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));

      return;
    }

    // tracking
    iterableAnalytics.addToCart(normalizedData.items, normalizedData.totalData.subtotal);
    if (trackingItemData) {
      GA.addToCart(trackingItemData, "add");
      gtagTrack.addToCart(trackingItemData);
      FBPixel.addToCart(trackingItemData);
    }

    dispatch({
      type: ACTION.GET_CHECKOUT_DATA,
      data: normalizedData,
      itemsQty: totalQty,
    });

    dispatch({
      type: ACTION.CART_FETCH_END,
    });
  };
};

function deleteItemToServer(token, item) {
  const request = new API(`/carts/mine/items/${item.item_id}`, "default");
  return request
    .deleteByUserToken(token, 60000)
    .then(() => ({
      type: "success",
    }))
    .catch(() => ({
      type: ERROR,
    }));
}

export const deleteItemFromCart = (item) => {
  return async function thunk(...args) {
    const [dispatch, getState] = args;
    const {cartId} = getState().cart;
    const {token} = getState().auth;
    let userCartId = cartId;

    dispatch({
      type: ACTION.CART_FETCH_START,
    });

    // if there is no user cart id,fetch it first
    if (!userCartId) {
      const serverResponse = await fetchUserCardId(token, dispatch);
      if (serverResponse.type === ERROR) {
        dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));
        return;
      }
      if (serverResponse.type === UNAUTHORIZE) {
        dispatch(handleCartFetchError("您已被登出，請重新登入"));
        return;
      }
      userCartId = serverResponse.cartId;
      dispatch({
        type: ACTION.UPDATE_CART_ID,
        cartId: userCartId,
      });
    }

    const serverResponse = await deleteItemToServer(token, item);
    if (serverResponse.type === ERROR) {
      dispatch(handleCartFetchError("目前系統繁忙中，暫時無法同步購物車，請稍後再試"));
      return;
    }
    const {error, normalizedData, totalQty} = await fetchCheckoutConfig(userCartId, token, dispatch);
    if (error) {
      dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));

      return;
    }

    // iterable
    iterableAnalytics.addToCart(normalizedData.items, normalizedData.totalData.subtotal);

    dispatch({
      type: ACTION.GET_CHECKOUT_DATA,
      data: normalizedData,
      itemsQty: totalQty,
    });

    dispatch({
      type: ACTION.CART_FETCH_END,
    });
  };
};

/**
 * Fetch checkout config action
 * @param {*} cartId
 * @param {*} token
 */
export function beginCheckout(cartId, token) {
  return async (dispatch) => {
    try {
      dispatch({
        type: ACTION.CART_FETCH_START,
      });
      const {error, normalizedData, totalQty} = await fetchCheckoutConfig(cartId, token);

      if (error) {
        dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));

        return;
      }
      dispatch({
        type: ACTION.GET_CHECKOUT_DATA,
        data: normalizedData,
        itemsQty: totalQty,
      });

      let hasOutOfStockItems = false;

      const {length} = normalizedData.items;

      for (let i = 0; i < length; i += 1) {
        if (normalizedData.items[i].outOfStock) {
          hasOutOfStockItems = true;
          break;
        }
      }

      dispatch({
        type: ACTION.CART_FETCH_END,
      });
      return hasOutOfStockItems;
    } catch (error) {
      dispatch(handleCartFetchError(DEFAULT_ERROR_MESSSAGE));

      return "error";
    }
  };
}

export const getFreeGifts = (appliedRuleIds, cartId) => {
  const request = new API(`/salesrule/getfreegifts?rule_ids=${appliedRuleIds}&quote_id=${cartId}`);
  return (dispatch) => {
    dispatch(showLoading());
    request
      .getData()
      .then((response) => {
        dispatch({
          type: "SET_FREEGIFTS",
          freeGifts: response.data,
        });
      })
      .catch(() => {
        dispatch({
          type: "ERROR_FREEGIFTS",
        });
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
};

export const addFreeGift = (productId, cartId, token) => {
  return async (dispatch) => {
    const request = new API(`/salesrule/addfreegift?product_id=${productId}&quote_id=${cartId}`);

    dispatch({
      type: ACTION.CART_FETCH_START,
    });

    dispatch(showLoading());

    const isAdded = await request.putData().then((response) => response.data !== -1);
    if (isAdded) {
      const {normalizedData, totalQty} = await fetchCheckoutConfig(cartId, token);
      if (normalizedData) {
        dispatch({
          type: ACTION.GET_CHECKOUT_DATA,
          data: normalizedData,
          itemsQty: totalQty,
        });
      }
    } else {
      dispatch({
        type: "SET_FREEGIFTS",
        freeGifts: [],
      });
    }
    dispatch({
      type: ACTION.CART_FETCH_END,
    });
  };
};

export function clearCart() {
  return {
    type: ACTION.CART_CLEAR,
  };
}

export function updateCouponCode(currentCouponCode) {
  return {
    type: ACTION.UPDATE_COUPON_CODE,
    appliedCouponCode: currentCouponCode,
  };
}

export function applyCouponCode(cartId, code, token, successCallback, errorCallback) {
  return (dispatch) => {
    const request = new API(`/carts/${cartId}/coupons/${code}`, "default", "V2");
    request
      .putByUserToken(token)
      .then(() => {
        successCallback(code);
        dispatch(updateCouponCode(code));
        iterableAnalytics.couponUsed(code);
      })
      .catch((error) => {
        errorCallback(error);
      });
  };
}

export function getCouponListAndFreeGifts(token, appliedRuleIds, cartId) {
  return (dispatch) => {
    const getCouponListRequest = new API("/customerpromotion/getcoupon", "default", "V1", 1).getByUserToken(token);
    const getFreeGiftsRequest = new API(`/salesrule/getfreegifts?rule_ids=${appliedRuleIds}&quote_id=${cartId}`).getData();

    dispatch(showLoading());
    Promise.all([getCouponListRequest, getFreeGiftsRequest])
      .then((responses) => {
        const [couponListResponse, freeGiftsResponse] = responses;
        const normalizedCouponList = normalizeCouponList(couponListResponse.data);
        dispatch({
          type: ACTION.UPDATE_COUPON_LIST,
          couponList: normalizedCouponList,
        });
        dispatch({
          type: "SET_FREEGIFTS",
          freeGifts: freeGiftsResponse.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: "ERROR_FREEGIFTS",
        });
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}
