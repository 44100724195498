import {
  CART_CLEAR,
  CART_FETCH_END,
  CART_FETCH_ERROR,
  CART_FETCH_START,
  CART_INITIALIZE,
  CART_INITIALIZE_END,
  GET_CHECKOUT_DATA,
  UPDATE_CART_ID,
  UPDATE_COUPON_CODE,
  UPDATE_COUPON_LIST,
  UPDATE_GUEST_CART,
  UPDATE_ITEM_TO_CART,
  UPDATE_ITEM_TO_CART_FAILED,
  UPDATE_PARTIAL_ITEM,
} from "@actions/cartActionType";

const initialState = {
  initialized: false,
  isFetching: false,
  fetchError: false,
  errorMessage: "",
  errorCount: 0,
  cartId: null,
  totalQtyCountInCart: 0,
  items: {},
  totalData: {
    grand_total: 0,
    subtotal: 0,
    discount_amount: 0,
    discount_title: "",
    subtotal_with_discount: 0,
    shipping_amount: 0,
    shipping_discount_amount: 0,
    coupon_code: "",
    coupon_discount: 0,
    items_qty: 0,
  },
  defaultShippingPrice: 0,
  applyRuleIds: "",
  couponList: [],
  appliedCouponCode: "",
  cartHashId: [], // hash ids
  freeGifts: [],
  isCartIdUpdating: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CART_FETCH_START:
      return {
        ...state,
        isFetching: true,
      };
    case CART_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchError: true,
        errorCount: state.errorCount + 1 >= 3 ? 0 : state.errorCount + 1,
        errorMessage: action.errorMessage,
      };
    case CART_FETCH_END:
      return {
        ...state,
        isFetching: false,
        fetchError: false,
      };
    case CART_INITIALIZE:
      return {
        ...state,
        isFetching: true,
        fetchError: false,
      };
    case CART_INITIALIZE_END:
      return {
        ...state,
        isFetching: false,
        initialized: true,
      };
    case UPDATE_GUEST_CART:
      return {
        ...state,
        items: {...action.newItems},
        totalData: {
          ...state.totalData,
          subtotal: action.subtotalWithDiscount,
          subtotal_with_discount: action.subtotalWithDiscount,
        },
        totalQtyCountInCart: Object.values(action.newItems).reduce((accumulator, currentValue) => {
          return accumulator + currentValue.qty;
        }, 0),
      };
    case UPDATE_ITEM_TO_CART:
      return {
        ...state,
        totalQtyCountInCart: state.totalQtyCountInCart + action.addedQty,
      };

    case UPDATE_ITEM_TO_CART_FAILED:
      return {
        ...state,
        totalQtyCountInCart: state.totalQtyCountInCart - action.subQty > 0 ? state.totalQtyCountInCart - action.subQty : 0,
      };
    case UPDATE_CART_ID:
      return {
        ...state,
        cartId: action.cartId,
      };
    case GET_CHECKOUT_DATA:
      return {
        ...state,
        items: action.data.items.reduce(
          (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue.item_id]: {...currentValue},
          }),
          {},
        ),
        totalData: {
          ...state.totalData,
          ...action.data.totalData,
        },
        applyRuleIds: action.data.applyRuleIds,
        defaultShippingPrice: action.data.defaultShippingPrice,
        appliedCouponCode: action.data.appliedCouponCode,
        totalQtyCountInCart: action.itemsQty,
      };
    case CART_CLEAR:
      return {
        initialized: false,
        isFetching: false,
        fetchError: false,
        errorCount: 0,
        cartId: null,
        totalQtyCountInCart: 0,
        items: {},
        errorMessage: "",
        totalData: {
          grand_total: 0,
          subtotal: 0,
          discount_amount: 0,
          discount_title: "",
          subtotal_with_discount: 0,
          shipping_amount: 0,
          shipping_discount_amount: 0,
          coupon_code: "",
          coupon_discount: 0,
          items_qty: 1,
          total_segments: [],
        },
        defaultShippingPrice: 0,
        applyRuleIds: "",
        cartHashId: [], // hash ids
        freeGifts: [],
        couponList: [],
      };
    case "SET_FREEGIFTS":
      return {
        ...state,
        freeGifts: action.freeGifts,
      };
    case "ERROR_FREEGIFTS":
      return {
        ...state,
        freeGifts: [],
      };
    case UPDATE_PARTIAL_ITEM:
      return {
        ...state,
        items: {...action.items},
      };
    case UPDATE_COUPON_CODE:
      return {
        ...state,
        appliedCouponCode: action.appliedCouponCode,
      };
    case UPDATE_COUPON_LIST:
      return {
        ...state,
        couponList: action.couponList,
      };
    default:
      return state;
  }
}
