// import { IS_APP_READY,APP_ERROR } from '../actions/appConfig';

const initialState = {
  isAppReady: false,
  apiError: false,
  canUseFBSDK: false,
  finalStore: "default",
  promotion_banner: {
    message: "",
    version: "",
  },
  canUseTappay: false, // load tappaysdk,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "IS_APP_READY":
      return {
        ...state,
        isAppReady: true,
        add_price_buy: action.add_price_buy,
        base_url: action.base_url,
        brands: action.brands,
        cvs_constrain: action.cvs_constrain,
        hot_sale_id: action.hot_sale_id,
        media_url: action.media_url,
        promotion_banner: action.promotion_banner,
        search_recommend: action.search_recommend,
        shipping_threshold: action.shipping_threshold,
        vendor_of_shipping: action.vendor_of_shipping,
      };
    case "APP_ERROR":
      return {
        ...state,
        isAppReady: false,
        apiError: true,
      };
    case "CAN_USE_FBSDK":
      return {
        ...state,
        canUseFBSDK: true,
        fbStatus: action.fbStatus,
        fbAuthResponse: action.fbAuthResponse,
      };
    case "SET_FINAL_STORE":
      return {
        ...state,
        finalStore: action.finalStore,
      };
    case "TAPPAYSDK_LOADED":
      return {
        ...state,
        canUseTappay: true,
      };

    default:
      return state;
  }
}
