const initialState = {
  membershipstate_id: "",
  customerGroupId: "", // vip or normal
  level: "",
  expired_date: "", // YYYY-MM-DD HH:MM:SS
  isAutoDeductionDefaultState: false,
  vipPaymentHistory: [],
  vipCreditCardNumber: "",
  isInfoChecked: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "VIP_UPDATE_GROUP_ID":
      return {
        ...state,
        customerGroupId: action.groupId, // vip or normal or ""
      };
    case "VIP_UPDATE_INFO":
      return {
        ...state,
        expiredDate: action.expiredDate,
        isAutoDeductionDefaultState: action.isAutoDeductionDefaultState,
        vipPaymentHistory: [...action.vipPaymentHistory],
        paymentCreditCardLastFour: action.paymentCreditCardLastFour,
        isInfoChecked: true,
        vipCardBinCode: action.vipCardBinCode,
      };
    case "VIP_SET_AUTO_DEDUCTION":
      return {
        ...state,
        isAutoDeductionDefaultState: true,
      };
    case "VIP_CLEAR_STATE":
      return {
        membershipstate_id: "",
        customerGroupId: "", // vip or normal
        level: "",
        expired_date: "", // YYYY-MM-DD HH:MM:SS
        isAutoDeductionDefaultState: false,
        vipPaymentHistory: [],
        vipCreditCardNumber: "",
        isInfoChecked: false,
      };
    default:
      return state;
  }
}
