import errorHandler from "@shared/errorHandler";
import API from "../shared/api";
import {hideLoading, showLoading} from "./global";

export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const CANCEL_SUCCESS = "CANCEL_SUCCESS";
export const GET_ORDERS = "GET_ORDERS";
export const START_ORDER_FETCH = "START_ORDER_FETCH";
export const END_ORDER_FETCH = "END_ORDER_FETCH";
export const UPDATE_SPECIFIC_ORDER = "UPDATE_SPECIFIC_ORDER";
export const HANDLE_ORDER_ERROR = "HANDLE_ORDER_ERROR";
export const GET_NEWEST_ORDER = "GET_NEWEST_ORDER";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const GET_MORE_ORDERS = "GET_MORE_ORDERS";
export const NO_MORE_ORDERS = "NO_MORE_ORDERS";
export const ORDER_FETCH_FAILED = "ORDER_FETCH_FAILED";
export const ORDER_REFRESH = "ORDER_REFRESH";
export const ORDER_SHOULD_UPDATE = "ORDER_SHOULD_UPDATE";

/**
 * Action Creator
 * @param { array } data - object arrays the returned by api
 * @param { number }
 */
function getOrders({items, orderIsEnd}) {
  return {
    type: GET_ORDERS,
    orders: items,
    orderIsEnd,
  };
}
/**
 * Action Creator
 * @param { array } data - object arrays the returned by api
 * @param { number }
 */
function getMoreOrders({items, orderIsEnd}) {
  return {
    type: GET_MORE_ORDERS,
    orders: items,
    orderIsEnd,
  };
}

function noMoreOrders() {
  return {
    type: NO_MORE_ORDERS,
  };
}

function fetchOrderFailed(errorMsg) {
  return {
    type: HANDLE_ORDER_ERROR,
    errorMsg,
  };
}

function cacncelSuccess(id) {
  return {
    type: CANCEL_SUCCESS,
    id,
  };
}

function startOrderFetch() {
  return {
    type: START_ORDER_FETCH,
  };
}

function endOrderFetch() {
  return {
    type: END_ORDER_FETCH,
  };
}

/**
 * === API and dispatch wrapper ====
 * Getting orders
 * @param { boolean } type: update behavior: "refresh" for get the first 8 order,
 * "lazyloading" for get more orders
 */
export function fetchUserOrders(type) {
  return async (dispatch, getState) => {
    const state = getState();

    let {currentEntity, orderTotalCount} = state.orders;
    const {token} = state.auth;

    switch (type) {
      case "refresh":
        // reset entity to initial
        currentEntity = 1;
        break;
      case "lazyloading":
        // do nothing
        // use currentEntity to fetch more orders
        // reducer
        break;
      default:
        // default
        // reset entity to initial, so we can get a whole new list
        currentEntity = 1;
        break;
    }

    // set searchCritera
    const api =
      "/customer/orders?" +
      "searchCriteria[pageSize]=6&" +
      `searchCriteria[currentPage]=${currentEntity}&` +
      "searchCriteria[sortOrders][0][field]=created_at&" +
      "searchCriteria[sortOrders][0][direction]=DESC";

    dispatch(startOrderFetch());

    const request = new API(api, "default");

    request
      .getByUserToken(token)
      .then((res) => {
        if (type === "lazyloading") {
          dispatch(getMoreOrders({items: res.data.items, orderIsEnd: res.data.total_count <= currentEntity * 6}));
        } else if (type === "refresh") {
          dispatch(getOrders({items: res.data.items, orderIsEnd: res.data.total_count <= currentEntity * 6}));
        } else {
          dispatch(getOrders({items: res.data.items, orderIsEnd: res.data.total_count <= currentEntity * 6}));
        }
      })
      .catch((error) => {
        errorHandler(error, true);
      })
      .then(() => {
        dispatch(endOrderFetch());
      });
  };
}

export function cancelOrder(orderId) {
  // api POST /V1/orders/{id}/cancel
  // api GET /V1/orders/{id}
  // cancelOrder
  return (dispatch) => {
    dispatch(startOrderFetch());
    dispatch(showLoading());
    const cancel = new API(`/orders/${orderId}/cancel`);
    return cancel
      .postData({
        id: orderId,
      })
      .then(
        (res) => {
          if (res) {
            // cancel success
            dispatch(cacncelSuccess(orderId));
            dispatch(endOrderFetch());
            // TODO: get the update data from server?
            // return updateSpecificOrder();
          } else {
            dispatch(fetchOrderFailed("failed"));
          }
          dispatch(hideLoading());
        },
        (err) => {
          dispatch(fetchOrderFailed(err.message));
          dispatch(hideLoading());
        },
      );
  };
}

export function getNewestOrder(id, token) {
  const request = new API(`/customer/orders/${id}`, "default");
  return request.getByUserToken(token);
}
export function clearOrders() {
  return {
    type: CLEAR_ORDERS,
  };
}
