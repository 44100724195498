import {UPDATE_PRODUCT_LIST} from "../actions/products";

const initialState = {
  products: {
    // 0: {
    //     id: 0,
    //     sku: "A1234567",
    //     name: "商品名稱",
    //     type_id: "商品類型",
    //     price: 100,
    //     list_price: 101,
    //     special_price: 99,
    //     special_from_date: "",
    //     special_to_date: "",
    //     stock: "庫存",
    //     is_in_stock: "有貨",
    //     vendor_of_shipping: 46,
    //     pre_order_shipping_date: "",
    //     description: "",
    //     short_description: "",
    //     imgUrl: "",
    //     edge: [
    //         1, 1, 1
    //     ],
    //     brand: 0
    //     // 屬性
    // }
  },
  currentProductIds: [],
  currentCategoryId: null,
};

export default function reducer(state = initialState, action) {
  let temp = {};
  switch (action.type) {
    case UPDATE_PRODUCT_LIST:
      action.products.forEach((element) => {
        temp = {
          ...temp,
          [element.id]: element,
        };
      });

      return {
        ...state,
        currentCategoryName: action.currentCategoryName,
        currentCategoryId: action.currentCategoryId,
        currentProductIds: action.currentProductIds,
        products: {
          ...temp,
          ...state.products,
        },
      };
    default:
      return state;
  }
}
