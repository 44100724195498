import {
  GET_FILTER_OPTIONS,
  GET_LIST,
  GET_MORE_LIST,
  GET_PROMO_FETCH,
  LIST_FETCH_END,
  LIST_REFRESH,
  LIST_START_FETCH,
  NO_MORE_LIST,
} from "@actions/list";

const initialState = {
  isFetching: false,
  refresh: true,
  id: undefined,
  brandId: undefined,
  products: [],
  hasMore: true,
  categoryName: "",
  filterOptions: [], // filters
  sortOptions: [],
  currentUserFitlers: "", // current user filters
  totalPage: 0,
  currentSort: "",
  currentDirection: "",
  promotionBlock: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LIST_START_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case LIST_FETCH_END:
      return {
        ...state,
        isFetching: false,
      };
    case LIST_REFRESH:
      return {
        ...state,
        refresh: true,
        products: [],
      };

    case NO_MORE_LIST:
      return {
        ...state,
        page: action.page, // reset page to 0
        products: [...state.products, ...action.products],
        hasMore: false,
      };
    case GET_LIST:
      return {
        ...state,
        products: action.products,
        sort: action.sort,
        id: action.id,
        currentUserFitlers: action.filter,
        productCount: action.productCount,
        categoryDescription: action.categoryDescription,
        categoryMetaDescription: action.categoryMetaDescription,
        categoryMetaTitle: action.categoryMetaTitle,
        categoryName: action.categoryName,
        categoryImage: action.categoryImage,
        refresh: false,
        hasMore: action.hasMore,
        sortOptions: action.sortOptions,
        totalPage: action.totalPage,
        defaultSort: action.defaultSort,
        defaultDirection: action.defaultDirection,
      };
    case GET_MORE_LIST:
      return {
        ...state,
        products: [...state.products, ...action.products],
        hasMore: action.hasMore,
      };
    case GET_FILTER_OPTIONS:
      return {
        ...state,
        filterOptions: action.filterOptions,
      };
    case GET_PROMO_FETCH:
      return {
        ...state,
        promotionBlock: action.promotionBlock,
      };
    default:
      return state;
  }
}
