import {FACEBOOK_CLEAR_DATA, FACEBOOK_LOGIN_FAILED, REGISTER_CLEAR_DATA, REGISTER_SET_DATA} from "@actions/register";

const initialState = {
  facebookLoginFailed: false,
  firstname: "",
  lastname: "",
  email: "",
  socialId: null,
  cansendOTP: true,
  cancelFB: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FACEBOOK_LOGIN_FAILED:
      return {
        ...state,
        facebookLoginFailed: true,
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
        socialId: action.socialId,
      };
    case FACEBOOK_CLEAR_DATA:
      return {
        facebookLoginFailed: false,
        firstname: "",
        lastname: "",
        email: "",
        socialId: null,
        cansendOTP: true,
        cancelFB: true,
      };
    case REGISTER_SET_DATA:
      return {
        ...state,
        ...action.data,
      };
    case REGISTER_CLEAR_DATA:
      return initialState;
    default:
      return state;
  }
}
