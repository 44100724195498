import API from "../shared/api";

export const IS_CMS_READY = "IS_CMS_READY";
export const CMS_ERROR = "CMS_ERROR";

/**
 * get CMS config
 */
export function getHomepageConfig(storeName) {
  const api = "/marketing/homepage";
  const call = new API(api, storeName);

  return (dispatch) => {
    call
      .getData()
      .then((response) => {
        dispatch({
          type: IS_CMS_READY,
          key: storeName,
          data: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CMS_ERROR,
        });
      });
  };
}
