import {
  DISABLE_REDIRECT,
  MODIFY_CAN_GO_PAYMENT,
  PLACE_ORDER_SUCCESS,
  RESET_CHECKOUT,
  UPDATE_CVS_CITY_REGION_FIELD,
  UPDATE_CVS_FIELDS,
  UPDATE_SHIPPING_SUCCESS,
  UPDATE_TOTAL_DATA,
} from "@actions/checkoutActionType";

const initialState = {
  canGoPayment: false, // will be updated only by step2
  redirectToStep3: false, // will be updated only by step2 and step3
  shippingInfo: {
    region: "中正區",
    street: "",
    postcode: "",
    city: "台北市",
    firstname: "",
    lastname: "",
    telephone: "",
  },
  cvsShippingInfo: {
    region: null,
    street: null,
    postcode: null,
    city: null,
    firstname: null,
    lastname: null,
    telephone: null,
  },
  methods: [],
  redirectPayment: true,
  placeOrderSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    // update cvs form region and street
    case UPDATE_CVS_CITY_REGION_FIELD:
      return {
        ...state,
        cvsShippingInfo: {
          ...state.cvsShippingInfo,
          city: action.region,
          region: action.region,
          street: action.street,
          postcode: action.postcode,
        },
      };
    case UPDATE_CVS_FIELDS:
      return {
        ...state,
        cvsShippingInfo: {
          ...state.cvsShippingInfo,
          [action.name]: action.value,
        },
      };
    case UPDATE_SHIPPING_SUCCESS:
      return {
        ...state,
        shippingInfo: action.shippingInfo,
        canGoPayment: true,
        redirectPayment: true,
      };

    case MODIFY_CAN_GO_PAYMENT:
      return {
        ...state,
        canGoPayment: action.state,
      };
    case UPDATE_TOTAL_DATA:
      return {
        ...state,
        totalData: action.totalData,
        methods: [...action.methods],
      };

    case DISABLE_REDIRECT:
      return {
        ...state,
        redirectPayment: false,
      };
    case PLACE_ORDER_SUCCESS:
      return {
        ...state,
        placeOrderSuccess: true,
      };
    case RESET_CHECKOUT:
      return {
        ...state,
        methods: [],
        placeOrderSuccess: false,
      };
    default:
      return state;
  }
}
