// eslint-disable-next-line import/prefer-default-export
function getStoreTitle(storeName) {
  switch (storeName) {
    case "proteinshop":
      return "運動吃蛋白";
    case "morningshop":
      return "早餐吃麥片";
    default:
      return "優馬選品";
  }
}

// eslint-disable-next-line import/prefer-default-export
export function setBreadcrumb({storeName, categoryName, product = undefined}) {
  const storeTitle = getStoreTitle(storeName);
  if (product) {
    return {
      type: "SET_BREADCRUMBS",
      storeName: storeTitle,
      categoryName,
      product: {
        id: product.id,
        name: product.name,
        currency: "TWD",
        unit_price: product.originalPrice,
        unit_sale_price: product.specialPrice,
        url: product.url,
        product_image_url: product.imgUrl,
      },
    };
  }
  return {
    type: "SET_BREADCRUMBS",
    storeName: storeTitle,
    categoryName,
  };
}
