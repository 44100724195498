import * as O from "../actions/orders";

const initialState = {
  cancelSuccess: false,
  isFetching: false,
  orders: [],
  currentEntity: 1,
  canceledId: null,
  orderIsEnd: false,
  orderRefresh: false,
  canGetNewestOrder: false,
  orderTotalCount: 0,
};

export default function reducer(state = initialState, action) {
  let newArray = [];
  switch (action.type) {
    case O.START_ORDER_FETCH:
      return {
        ...state,
        isFetching: true,
        cancelSuccess: false,
        canceledId: null,
      };
    case O.END_ORDER_FETCH:
      return {
        ...state,
        isFetching: false,
        orderRefresh: false,
      };
    case O.ORDER_FETCH_FAILED:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.errorMsg,
      };
    case O.GET_ORDERS:
      // check order length
      return {
        ...state,
        orders: action.orders,
        currentEntity: 2, // update current page to 2
        orderIsEnd: action.orderIsEnd,
      };
    case O.ORDER_REFRESH:
      return {
        ...state,
        orderRefresh: true,
      };
    case O.GET_MORE_ORDERS:
      return {
        ...state,
        orders: [...state.orders, ...action.orders],
        currentEntity: state.currentEntity + 1,
        orderIsEnd: action.orderIsEnd,
      };
    case O.NO_MORE_ORDERS:
      return {
        ...state,
        orderIsEnd: true,
      };

    case O.CANCEL_SUCCESS:
      newArray = state.orders.map((item, index) => {
        if (item.entity_id !== id) {
          // This isn't the item we care about - keep it as-is
          return item;
        } else {
          item.status = "canceled";
          return item;
        }
      });
      return {
        ...state,
        cancelSuccess: true,
        canceledId: action.id,
        orders: newArray,
      };
    case O.GET_NEWEST_ORDER:
      return {
        ...state,
        canGetNewestOrder: false, // reset canGetNewesOrder,
        orders: [action.orderData, ...state.orders],
      };
    case O.UPDATE_SPECIFIC_ORDER:
      let id = action.orderData.entity_id;
      // update old data
      newArray = state.orders.map((item, index) => {
        if (item.entity_id !== id) {
          // This isn't the item we care about - keep it as-is
          return item;
        } else {
          // Otherwise, this is the one we want - return an updated value
          return action.orderData;
        }
      });

      return {
        ...state,
        orders: newArray,
      };
    case O.HANDLE_ORDER_ERROR:
      return {
        ...state,
      };
    case O.CLEAR_ORDERS:
      return {
        ...state,
        cancelSuccess: false,
        isFetching: false,
        orders: [],
        currentEntity: 1,
        canceledId: null,
        orderIsEnd: false,
        orderRefresh: false,
        canGetNewestOrder: false,
      };
    case O.ORDER_SHOULD_UPDATE:
      return {
        ...state,
        canGetNewestOrder: true,
      };
    default:
      return state;
  }
}
