// @flow
import checkSEOParam from "@shared/checkSEOParam";
import errorHandler from "@shared/errorHandler";
import {formatProductList} from "@shared/productHelpers";
import API from "../shared/api";
import {updateProductLst} from "./products";
import {getProductWishCount} from "./wishlist";

export const CATEGORY_FETCH_START = "CATEGORY_FETCH_START";
export const CATEGORY_FETCH_END = "CATEGORY_FETCH_END";
export const CATEGORY_FETCH_ERROR = "CATEGORY_FETCH_ERROR";

export const SAVE_STORE_CATEGORIES = "SAVE_STORE_CATEGORIES";
export const UPDATE_CATEGORY_PRODUCTS = "UPDATE_CATEGORY_PRODUCTS";
export const GET_CURRENT_FILTERS = "GET_CURRENT_FILTERS";

function normalize(data) {
  const hashmap = {};
  const hashmapIds = [];
  let rootId;

  data.forEach((element) => {
    let childrenDataIds = [];
    if (element.is_active) {
      hashmapIds.push(element.id);
      if (element.children_data) {
        // filter only is_active: true
        const activeChildren = element.children_data.filter((ele) => ele.is_active);
        childrenDataIds = activeChildren.map((ele) => ele.id);
        if (!rootId && activeChildren.length > 0) {
          rootId = element.id;
        }
      }
      hashmap[element.id] = {
        id: element.id,
        level: element.level,
        parent_id: element.parent_id,
        position: element.position,
        product_count: element.product_count,
        nextLevelIds: childrenDataIds,
        name: element.name,
        icon: element.icon,
      };
    }
  });

  return {
    hashmap,
    hashmapIds,
    rootId,
  };
}

function saveCategories(storeName, topLevelCategory, topLevelCategoryIds, secondLevelCategory, rootId) {
  return {
    type: SAVE_STORE_CATEGORIES,
    storeName,
    topLevelCategory,
    topLevelCategoryHashIds: topLevelCategoryIds,
    secondLevelCategory,
    rootId,
  };
}

export function getCategories(storeName) {
  const request = new API("/category/menulst", storeName);

  return (dispatch) => {
    dispatch({
      type: CATEGORY_FETCH_START,
    });

    return request
      .getData()
      .then(
        (res) => {
          const normalizedTopLevelData = normalize(res.data.children_data);

          let normalizedSecondLevelData = {};
          // normalize secondLevel data
          res.data.children_data.forEach((ele) => {
            const temp = normalize(ele.children_data);

            normalizedSecondLevelData = {
              ...normalizedSecondLevelData,
              ...temp.hashmap,
            };
          });

          dispatch(
            saveCategories(
              storeName,
              normalizedTopLevelData.hashmap,
              normalizedTopLevelData.hashmapIds,
              normalizedSecondLevelData,
              normalizedTopLevelData.rootId,
            ),
          );
          dispatch({
            type: CATEGORY_FETCH_END,
          });
        },
        () => {
          dispatch({
            type: CATEGORY_FETCH_ERROR,
          });
        },
      )
      .catch(() => {
        dispatch({
          type: CATEGORY_FETCH_ERROR,
        });
      });
  };
}

/**
 * Get filters by categoryId
 * @param {int} categoryId
 * @param {string} storeName
 */
export function getFilterByCateId(categoryId, storeName) {
  const api = `/category/filters?category_id=${categoryId}`;
  const call = new API(api, storeName);

  return (dispatch) => {
    return call
      .getData()
      .then((res) => {
        dispatch({
          type: GET_CURRENT_FILTERS,
          filterLst: res.data,
        });
      })

      .catch((error) => {
        errorHandler(error, false);
      });
  };
}

/**
 * [API] Get products by category id
 *
 * @param {int} categoryId
 * @param {int} currentPage
 * @param {int} size
 * @param {string} sort
 * @param {string} direc
 * @param {string} filter: 使用Json，格式為 {"attribute_1":["value_1","value_2"],"attribute_2":["value_1"]}
 * ex. http://test.urmart.com/rest/default/V2/category/products?categoryId=7&currentPage=2&size=10&direc=asc&sort=newest&filter={"brand":["70","76"],"vendorofshipping":["46"]}
 */
export function getProductsByCateId(configs) {
  // 確認網址列是否有 ?seo=1 參數
  const SEOParam = checkSEOParam();
  // set configs with default value
  const settings = {
    categoryId: configs.categoryId,
    storeName: configs.storeName || "default",
    currentPage: configs.currentPage || 1,
    size: configs.size || 30,
    sort: configs.sort || "bestseller",
    direc: configs.direc || "desc",
    filter: configs.filter || "",
  };

  // parse filter to string
  const params = {
    currentPage: settings.currentPage,
    size: settings.size,
    sort: settings.sort,
    direc: settings.direc,
    filter: settings.filter,
    categoryId: settings.categoryId,
    seo: SEOParam === "1" ? 1 : undefined,
  };
  const api = "/category/products";

  const request = new API(api, settings.storeName, "V3");

  return (dispatch) => {
    dispatch({
      type: CATEGORY_FETCH_START,
    });

    return request
      .getData(params)
      .then((res) => {
        const productList = res.data.products;
        const currentId = res.data.category_id;
        const categoryName = res.data.category_name;

        /**
         * 更新商品：
         * 將商品資訊獨立存成 Object Array 形式
         */
        const productIds = productList.map((element) => element.id);
        const formattedProductList = formatProductList(productList);

        dispatch(updateProductLst(formattedProductList, productIds, categoryName, currentId));

        /**
         * 更新分類：
         * 不存商品完整資料，只存分類和商品關聯
         */
        dispatch({
          type: UPDATE_CATEGORY_PRODUCTS,
          productList: productIds,
          id: res.data.category_id,
        });
        /**
         * 獲取 Wishcount
         */
        dispatch(getProductWishCount(productIds));
      })
      .catch((error) => {
        dispatch({
          type: CATEGORY_FETCH_ERROR,
        });
      });
  };
}
