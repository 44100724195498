import {CLEAR_MESSAGE, HIDE_LOADING, SHOW_LOADING, SHOW_MESSAGE, VISIT_EVENT_SENT} from "../actions/global";

const defaultState = {
  message: "",
  messageType: "", // notification or error
  showMessage: false,
  storeName: "default",
  showLoading: false,
  showActionButton: false,
  isVisitEventSent: false, // for iterable visit event
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SHOW_MESSAGE:
      return {
        ...state,
        messageType: action.messgeType,
        message: action.message,
        showMessage: true,
        showActionButton: action.showActionButton,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
        messageType: "",
        showMessage: false,
        showActionButton: false,
      };
    case SHOW_LOADING:
      return {
        ...state,
        showLoading: true,
      };
    case HIDE_LOADING:
      return {
        ...state,
        showLoading: false,
      };
    case VISIT_EVENT_SENT:
      return {
        ...state,
        isVisitEventSent: true,
      };
    default:
      return state;
  }
}
