import {
  COMPUTE_REVIEWS_LENGTH,
  GET_PRODUCT_REVIEWS_SUCCESS,
  SEE_MORE_REVIEWS,
  START_REVIEWS_FETCHING,
  FINISH_REVIEWS_FETCHING,
} from "@actions/review";

const initialState = {
  isFetched: false,
  reviews: [],
  totalPage: 1,
  reviewsLength: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: action.reviews,
        totalPage: action.totalPage,
      };
    case COMPUTE_REVIEWS_LENGTH:
      return {
        ...state,
        reviewsLength: action.reviewsLength,
      };
    case SEE_MORE_REVIEWS:
      return {
        ...state,
        reviews: [...state.reviews, ...action.reviews],
      };
    case START_REVIEWS_FETCHING:
      return {
        ...state,
        isFetched: false,
      };
    case FINISH_REVIEWS_FETCHING:
      return {
        ...state,
        isFetched: true,
      };
    default:
      return state;
  }
}
