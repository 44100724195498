import {checkAsked, getAdultContentPermissionFromCookie, setAdultContentPermissionInCookie} from "@storage/storage";

// eslint-disable-next-line import/prefer-default-export
export function setAdultContentPermission(permission) {
  return {
    type: "SET_PERMISSION",
    permission,
  };
}

export function checkAdultContentPermission() {
  const isAsked = checkAsked();

  return (dispatch) => {
    if (!isAsked) {
      setAdultContentPermissionInCookie(false);
      dispatch(setAdultContentPermission(false));
    } else {
      const permission = getAdultContentPermissionFromCookie();
      dispatch(setAdultContentPermission(permission));
    }
  };
}
