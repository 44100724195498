import API from "@shared/api";
import errorHandler from "@shared/errorHandler";
import CRMInsider from "@shared/insder";
import iterableAnalytics from "@shared/iterableAnalytics";
import {BrowserStorage} from "@storage/storage";
import {useSelector} from "react-redux";
import {hideLoading, showLoading, showMessage} from "./global";

const storage = new BrowserStorage();

export function normalizedVipMemberInfo(memberInfo) {
  const {vip_credit_card_last_four, is_auto_deduction, vip_info_history, vip_credit_card_bin_code, expired_date} = memberInfo;
  return {
    isAutoDeductionDefaultState: is_auto_deduction === "1",
    vipCardBinCode: vip_credit_card_bin_code,
    expiredDate: expired_date,
    vipPaymentHistory: vip_info_history.map((history) => ({
      date: history.date,
      content: history.content,
      effectivePeriod: history.effective_period,
      cardBinCode: history.payment_credit_card_bin_code,
      lastFourDigit: history.payment_credit_card_last_four,
      total: history.total,
      paymentDate: history.payment_date,
    })),
    paymentCreditCardLastFour: vip_credit_card_last_four,
  };
}

export function getVIPInfo() {
  return function thunk(dispatch, getState) {
    const request = new API("/customermanager/vipInfo");
    const {token} = getState().auth;
    return request
      .getByUserToken(token)
      .then((response) => {
        const {
          expiredDate,
          isAutoDeductionDefaultState,
          vipPaymentHistory,
          paymentCreditCardLastFour,
          vipCardBinCode,
          paymentDate,
        } = normalizedVipMemberInfo(response.data);
        dispatch({
          type: "VIP_UPDATE_INFO",
          expiredDate,
          isAutoDeductionDefaultState,
          vipPaymentHistory,
          paymentCreditCardLastFour,
          vipCardBinCode,
        });
      })
      .catch((error) => {
        errorHandler(error, true);
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}

export function updatePaymentInfo(prime) {
  return function thunk(dispatch, getState) {
    const {token} = getState().auth;
    const udpatePaymentInfo = new API("/customerpromotion/updatePaymentInfo");

    dispatch(showLoading());
    return udpatePaymentInfo
      .putByUserToken(token, {prime})
      .then(() => {
        dispatch(getVIPInfo());
      })
      .catch((error) => {
        dispatch(showMessage("error", error.message, true));
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}

export function setVIPAutiDeduction(prime) {
  return function thunk(dispatch, getState) {
    const {token} = getState().auth;
    const udpatePaymentInfo = new API("/customerpromotion/updatePaymentInfo");
    const openAutoDeduction = new API("/customerpromotion/openAutoDeductionState");
    dispatch(showLoading());
    return udpatePaymentInfo
      .putByUserToken(token, {prime})
      .then(() => openAutoDeduction.putByUserToken(token))
      .then((response) => {
        if (response.data === "ok") {
          dispatch({
            type: "VIP_SET_AUTO_DEDUCTION",
          });
          dispatch(getVIPInfo());
        }
      })
      .catch((error) => {
        dispatch(showMessage("error", error.message, true));
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}

export function setCustomerGroup() {
  return function thunk(dispatch, getState) {
    const {token, email} = getState().auth;
    const fetchCustomerGroup = new API("/customermanager/getCustomerGroup");
    dispatch(fetchCustomerGroupId());
    return fetchCustomerGroup
      .getByUserToken(token)
      .then((res) => {
        const id = parseInt(res.data, 10);
        dispatch(setCustomerGroupId(id));
        storage.resetGroupId(id);
        CRMInsider.setUserData({
          ...window.insider_object.user,
          group_id: id === 4 ? "vip" : "normal",
        });
        iterableAnalytics.setCustomerGroup(email, id);
      })
      .catch((error) => errorHandler(error, true));
  };
}

export function setCustomerGroupId(groupId) {
  return {
    type: "VIP_UPDATE_GROUP_ID",
    groupId: groupId === 4 ? "vip" : "normal",
  };
}

export function clearVIPState() {
  return {
    type: "VIP_CLEAR_STATE",
  };
}

export function fetchCustomerGroupId() {
  return {
    type: "FETCH_CUTOMER_GROUP_ID",
  };
}

/**
 * Use Redux hooks to get customerGroupId
 */
export function getCustomerGroupId() {
  const customerGroupId = useSelector((state) => state.vip.customerGroupId);
  return customerGroupId;
}
