import {fbId} from "@shared/configs";
import iterableAnalytics from "@shared/iterableAnalytics";
import {BrowserStorage, getLocalAppConfig, saveLocalAppConfig} from "@storage/storage";
import API from "../shared/api";
import {keepLoginState, loginFailed} from "./auth";
import {setCustomerGroup} from "./vip";

export const IS_APP_READY = "IS_APP_READY";
export const APP_ERROR = "APP_ERROR";

const storage = new BrowserStorage();

function validateUserToken() {
  // get customer token from
  const token = storage.getToken();
  const customerData = storage.getCustomerData();

  if (token && customerData) {
    return {
      token,
      customer: {...customerData},
    };
  }

  if (token && !customerData) {
    const exchangeCall = new API("/customermanager/exchange", "default");
    return exchangeCall
      .postByUserToken(token)
      .then((response) => {
        storage.saveToken(response.data.token);
        storage.setCustomerData(response.data.customer);
        return response.data;
      })
      .catch(() => {
        return null;
      });
  }

  return null;
}

async function getAppConfig() {
  const localStateConfig = getLocalAppConfig();

  if (localStateConfig) {
    return {...localStateConfig};
  }

  const call = new API("/app/config", "default");
  try {
    const response = await call.getData();
    saveLocalAppConfig(response.data);
    return response.data;
  } catch (error) {
    return null;
  }
}

export function setFinalStore(finalStore) {
  return {
    type: "SET_FINAL_STORE",
    finalStore,
  };
}

export function fetchAppData() {
  return async (dispatch) => {
    // wait for 2 API done
    const customerData = await validateUserToken();
    const appConfig = await getAppConfig();

    if (customerData) {
      const {email = "", firstname = "", id = 0, lastname = "", custom_attributes = []} = customerData.customer;
      const {value: phone} = custom_attributes[0];
      iterableAnalytics.setUser(customerData.customer);

      dispatch(
        keepLoginState({
          customer: customerData.customer,
          token: customerData.token,
        }),
      );
      dispatch(setCustomerGroup());
    } else {
      dispatch(loginFailed());
    }

    // check there is appConfig
    if (appConfig) {
      const {
        add_price_buy,
        base_url,
        brands,
        cvs_constrain,
        hot_sale_id,
        media_url,
        promotion_banner,
        search_recommend,
        shipping_threshold,
        vendor_of_shipping,
      } = appConfig;

      dispatch({
        type: IS_APP_READY,
        add_price_buy,
        base_url,
        brands,
        cvs_constrain,
        hot_sale_id,
        media_url,
        promotion_banner,
        search_recommend,
        shipping_threshold,
        vendor_of_shipping,
      });
    } else {
      // any error of appConfig
      dispatch({
        type: APP_ERROR,
      });
    }
  };
}

/**
 * get fb sdk
 */
export function getSDK() {
  function loadError() {
    console.error("fbsdk load error");
  }

  function affixScriptToHead(url, onloadFunction) {
    const newScript = document.createElement("script");

    newScript.onerror = loadError;

    if (onloadFunction) {
      newScript.onload = onloadFunction;
    }

    document.head.appendChild(newScript);
    newScript.src = url;
  }

  return (dispatch) => {
    // load fb sdk
    affixScriptToHead("https://connect.facebook.net/zh_TW/sdk.js", () => {
      window.FB.init({
        appId: fbId,
        status: true, // check fb state when load fbsdk
        xfbml: true,
        version: "v3.2", // or v2.6, v2.5, v2.4, v2.3
      });

      // check login state
      window.FB.getLoginStatus((response) => {
        dispatch({
          type: "CAN_USE_FBSDK",
          fbStatus: response.status,
          fbAuthResponse: response.authResponse,
        });
      });

      // subscribe event
      window.FB.Event.subscribe("auth.statusChange", (response) => {
        dispatch({
          type: "CAN_USE_FBSDK",
          fbStatus: response.status,
          fbAuthResponse: response.authResponse,
        });
      });
    });
  };
}

/**
 * get tappay sdk
 */
export function getTappaySDK() {
  // define some script handle funciton
  function loadError() {}
  function affixScriptToHead(url, onloadFunction) {
    const newScript = document.createElement("script");

    newScript.onerror = loadError;

    if (onloadFunction) {
      newScript.onload = onloadFunction;
    }

    document.head.appendChild(newScript);
    newScript.src = url;
  }

  return (dispatch) => {
    // load tappay sdk
    affixScriptToHead("https://js.tappaysdk.com/tpdirect/v5.1.0", () => {
      dispatch({
        type: "TAPPAYSDK_LOADED",
      });
    });
  };
}
