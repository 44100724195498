// @flow
import {
  CATEGORY_FETCH_END,
  CATEGORY_FETCH_ERROR,
  CATEGORY_FETCH_START,
  GET_CURRENT_FILTERS,
  SAVE_STORE_CATEGORIES,
  UPDATE_CATEGORY_PRODUCTS,
} from "../actions/categories";

const initialState = {
  topLevelCategory: {},
  topLevelCategoryIds: {},
  secondLevelCategory: {},
  currentProductIds: [],
  currentCategoryId: null,
  currentCategoryName: null,
  categoryProducts: {},
  isFetching: false,
  fetchError: false,
  rootIds: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CATEGORY_FETCH_START:
      return {
        ...state,
        isFetching: true,
        fetchError: false,
      };
    case CATEGORY_FETCH_END:
      return {
        ...state,
        isFetching: false,
        fetchError: false,
      };
    case CATEGORY_FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        fetchError: true,
      };
    case SAVE_STORE_CATEGORIES:
      return {
        ...state,
        topLevelCategory: {
          ...state.topLevelCategory,
          [action.storeName]: {
            ...action.topLevelCategory,
          },
        },
        topLevelCategoryIds: {
          ...state.topLevelCategoryIds,
          [action.storeName]: action.topLevelCategoryHashIds,
        },
        secondLevelCategory: {
          ...state.secondLevelCategory,
          [action.storeName]: {
            ...action.secondLevelCategory,
          },
        },
        rootIds: {
          ...state.rootIds,
          [action.storeName]: action.rootId,
        },
      };
    case UPDATE_CATEGORY_PRODUCTS:
      return {
        ...state,
        currentProductIds: action.productList,
        currentCategoryId: action.id,
        categoryProducts: {
          ...state.categoryProducts,
          [action.id]: action.productList,
        },
        isFetching: false,
      };
    case GET_CURRENT_FILTERS:
      return {
        ...state,
        currentFilters: action.filterLst,
      };
    default:
      return state;
  }
}
