import {s3HostWithCache} from "@shared/configs";
import {captureSentryException} from "@shared/setupSentry";

const getDiscountTitle = (checkoutConfigs) => {
  let discountTitle = "目前無符合條件的優惠";

  try {
    const discount = checkoutConfigs.totals_data.total_segments.find((obj) => obj.code === "discount");
    if (discount) {
      discountTitle = discount.title;
    }
  } catch (error) {
    console.error(error);
  }

  return discountTitle;
};

const normalizeCartItems = (checkoutConfigs) => {
  // processing cart items
  const cartItems = checkoutConfigs.totals_data.items.map((element) => {
    let itemName = element.name;
    let outOfStock = false;

    try {
      // if preorder, add {預購商品}
      if (element.extension_attributes.product_attribute.vendor_of_shipping === "preorder") {
        itemName = `{預購商品}-${element.name}`;
      }
      outOfStock = !(element.qty <= element.extension_attributes.product_attribute.qty) || false;
    } catch (error) {
      captureSentryException("normalze cart data error", error);
    }

    return {
      item_id: element.item_id,
      name: itemName,
      price: element.price,
      discount_amount: element.discount_amount,
      row_total_with_discount: element.row_total_with_discount,
      qty: element.qty,
      sku: element.extension_attributes.product_attribute.sku,
      product_id: element.extension_attributes.product_attribute.id,
      product_description: element.extension_attributes.product_attribute.description || "",
      product_short_description: element.extension_attributes.product_attribute.short_description || "",
      stock: element.extension_attributes.product_attribute.qty,
      imgUrl: `${s3HostWithCache}${element.extension_attributes.product_attribute.image_path}`,
      vendor_of_shipping: element.extension_attributes.product_attribute.vendor_of_shipping,
      pre_order_shipping_date: element.extension_attributes.product_attribute.pre_order_shipping_date,
      isGift: Object.keys(element.extension_attributes).indexOf("amasty_promo") !== -1,
      width: element.extension_attributes.product_attribute.width || 100,
      length: element.extension_attributes.product_attribute.length || 100,
      height: element.extension_attributes.product_attribute.height || 100,
      outOfStock,
      isVIPLimited: element.extension_attributes.product_attribute.is_vip_limited === "1",
      hideVIPPrice: element.extension_attributes.product_attribute.hide_the_price === "1",
    };
  });
  return cartItems;
};

const getTotalData = (checkoutConfigs) => {
  const totalData = {
    grand_total: checkoutConfigs.totals_data.grand_total,
    subtotal: checkoutConfigs.totals_data.subtotal,
    discount_amount: checkoutConfigs.totals_data.discount_amount,
    discount_title: getDiscountTitle(checkoutConfigs),
    subtotal_with_discount: checkoutConfigs.totals_data.subtotal_with_discount,
    shipping_amount: checkoutConfigs.totals_data.shipping_amount,
    shipping_discount_amount: checkoutConfigs.totals_data.shipping_discount_amount || 0,
    items_qty: checkoutConfigs.totals_data.items_qty,
  };
  return totalData;
};

const getDefaultShippingRate = (checkoutConfigs) => {
  try {
    const shippingRate = checkoutConfigs.filter((shippintRate) => {
      const temp = shippintRate.value.filter((ele) => ele.value === "flatrate_flatrate");
      if (temp) {
        return true;
      }
      return false;
    });

    return shippingRate[0].price;
  } catch (error) {
    return 80;
  }
};

export const normalizeCheckoutData = (checkoutConfigs) => {
  return {
    applyRuleIds: checkoutConfigs.applied_rule_ids,
    defaultShippingPrice: getDefaultShippingRate(checkoutConfigs),
    totalData: getTotalData(checkoutConfigs),
    items: normalizeCartItems(checkoutConfigs),
    appliedCouponCode: checkoutConfigs.coupon_code || "",
  };
};

const normalizeShippingItemList = (data) => {
  const items = data.reduce((accumulator, currentValue) => {
    return {
      ...accumulator,
      [currentValue.item_id]: {
        price: currentValue.price,
        discount_amount: currentValue.discount_amount,
        row_total_with_discount: currentValue.row_total_with_discount,
        qty: currentValue.qty,
      },
    };
  }, {});

  return items;
};

export function updateShippingItems(currentItems, newItems) {
  const normalizedNewItems = normalizeShippingItemList(newItems);
  const finalItems = {};
  Object.entries(currentItems).forEach((keyValuePairedArray) => {
    const itemId = keyValuePairedArray[0];
    const value = keyValuePairedArray[1];
    const tempItem = normalizedNewItems[itemId];

    if (tempItem) {
      finalItems[itemId] = {
        ...value,
        price: tempItem.price,
        discount_amount: tempItem.discount_amount,
        row_total_with_discount: tempItem.row_total_with_discount,
        qty: tempItem.qty,
      };
    }
  });

  return finalItems;
}
