import {facebookLoginFailed} from "@actions/register";
import CRMInsider from "@shared/insder";
import iterableAnalytics from "@shared/iterableAnalytics";
import {clearLocalCart} from "@storage/cartStorage";
import {BrowserStorage, clearWishlistProductsFromLocal} from "@storage/storage";
import {EMAIL, NAME, TELEPHONE} from "@types/CustomerEditInfoTypes";
import API from "../shared/api";
import {clearSentryUserEmail, setSentryUserEmail} from "../shared/setupSentry";
import {LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT, RESET_FB_LOGIN_STATE, UPDATE_CUSTOMER_INFO} from "./authActionType";
import {clearCart} from "./cart";
import {hideLoading, showLoading, showMessage} from "./global";
import {clearOrders} from "./orders";
import {clearVIPState, setCustomerGroupId} from "./vip";
import {clearWishlistAction} from "./wishlist";

const storage = new BrowserStorage();

function setUserData(customerData) {
  storage.saveToken(customerData.token);
  storage.setCustomerData(customerData.customer);
  storage.setIsLogin();
  setSentryUserEmail(customerData.customer.email, customerData.customer.id);

  CRMInsider.setUserData({
    email: customerData.customer.email,
    name: customerData.customer.firstname,
    surname: customerData.customer.lastname,
    group_id: customerData.customer.group_id === 4 ? "vip" : "normal",
  });
}

export function loginSuccess(data) {
  setUserData(data);
  return {
    type: LOGIN_SUCCESS,
    token: data.token,
    customer: data.customer,
  };
}

export function keepLoginState(customerData) {
  setSentryUserEmail(customerData.customer.email, customerData.customer.id);
  CRMInsider.setUserData({
    email: customerData.customer.email,
    name: customerData.customer.firstname,
    surname: customerData.customer.lastname,
    group_id: customerData.customer.groupId === 4 ? "vip" : "normal",
  });
  storage.setIsLogin();

  return {
    type: LOGIN_SUCCESS,
    token: customerData.token,
    customer: customerData.customer,
  };
}

export function loginFailed() {
  return {
    type: LOGIN_FAILED,
  };
}

export function logout() {
  return {
    type: LOGOUT,
  };
}

export function clearUserData() {
  return (dispatch) => {
    storage.clearToken();
    clearLocalCart();
    clearWishlistProductsFromLocal();
    clearSentryUserEmail();
    dispatch(clearCart());
    dispatch(clearVIPState());
    dispatch(clearWishlistAction());
    dispatch(clearOrders());
    dispatch(logout());
  };
}

export function resetFBLoginState() {
  return {
    type: RESET_FB_LOGIN_STATE,
  };
}

/**
 * Normal login
 * @param {string} username User's telephone or email
 * @param {string} userpassword User's password
 */
export function fetchUser(username, userpassword) {
  // 加timestamp：繞過cache層 看情況有無改善
  const timestamp = new Date().getTime();
  const loginCall = new API(`/customermanager/login?timestamp=${timestamp}`, "default");
  return (dispatch) => {
    dispatch(showLoading());

    const userData = {
      username,
      password: userpassword,
    };

    return loginCall
      .postData(userData)
      .then(
        (response) => {
          const {customer} = response.data;
          dispatch(loginSuccess(response.data));
          dispatch(setCustomerGroupId(response.data.customer.group_id));

          // iterable
          iterableAnalytics.setUser(customer);
          iterableAnalytics.completeLogin(customer);
        },
        (error) => {
          dispatch(loginFailed());
          dispatch(showMessage("error", error.message, true));
        },
      )
      .catch(() => {
        dispatch(loginFailed());
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}

/**
 * facebook login
 * @param {Object} fbAuthResponse
 * @param {string} fbAuthResponse.socialId
 * @param {string} fbAuthResponse.firstName
 * @param {string} fbAuthResponse.lastName
 * @param {string} fbAuthResponse.email
 */
export function facebookLogin(fbAuthResponse) {
  return (dispatch) => {
    const socialLogin = new API("/customermanager/social/login", "default", "V2");
    if (fbAuthResponse) {
      dispatch(showLoading());

      socialLogin
        .postData({
          social_id: fbAuthResponse.socialId,
          type: "Facebook",
          // 如果 FB 沒有 email, 則帶入空字串, 如果沒有帶入空字串, API 在沒有該 FB 帳號的情況下會回傳 400 而不是 404
          email: fbAuthResponse.email || "",
        })
        .then(
          (response) => {
            const {customer} = response.data;
            dispatch(loginSuccess(response.data));
            dispatch(setCustomerGroupId(response.data.customer.group_id));

            // iterable
            iterableAnalytics.setUser(customer);
            iterableAnalytics.completeLogin(customer);
          },
          (error) => {
            if (error && error.status === 404) {
              // user does not register, tell component to go register page
              dispatch(facebookLoginFailed(fbAuthResponse));
            }
          },
        )
        .catch(() => {
          dispatch(showMessage("error", "Facebook無法登入", true));
        })
        .then(() => {
          dispatch(hideLoading());
        });
    }
  };
}

export function updateUserInfo({userFirstName, userLastName, userEmail, userTelephone, infoType}) {
  return (dispatch, getState) => {
    const updateUserInfoRequest = new API("/customermanager/updateUserBasicInfo");
    const {token} = getState().auth;

    dispatch(showLoading());

    return updateUserInfoRequest
      .putByUserToken(
        token,
        {
          userFirstName,
          userLastName,
          userEmail,
          userPhoneNumber: userTelephone,
        },
        90000,
      )
      .then(
        () => {
          let message = "資料更新成功！";
          if (infoType === NAME) {
            message = "姓名更新成功！";
          } else if (infoType === TELEPHONE) {
            message = "驗證成功！ 手機號碼更新完成。下次登入時請使用新手機號碼登入。";
          } else if (infoType === EMAIL) {
            message = "Email 更新成功！下次登入時請使用新Email登入。";
          }
          dispatch({
            type: UPDATE_CUSTOMER_INFO,
            userFirstName,
            userLastName,
            email: userEmail,
            telephone: userTelephone,
          });
          dispatch(showMessage("notification", message, true));

          // 讓 Local 端的 customer data 失效, 下次使用者進入網站就會再更新 Local 端的資料
          const localCustomerData = storage.getCustomerData();
          if (localCustomerData) {
            const newCustomerData = {
              ...localCustomerData,
              invalidate: true,
            };
            storage.setCustomerData(newCustomerData);
          }
          return "successed";
        },
        (error) => {
          let message = "";
          if (infoType === NAME) {
            message = `姓名更新失敗！${error.message}`;
          } else if (infoType === TELEPHONE) {
            message = `手機更新失敗！${error.message}`;
          } else if (infoType === EMAIL) {
            message = `Email 更新失敗！${error.message}`;
          }
          dispatch(showMessage("error", message, true));
          return "failed";
        },
      )
      .catch(() => {
        // Unexpected error
        dispatch(showMessage("notification", "資料更新失敗", true));
        return "failed";
      })
      .then(() => {
        dispatch(hideLoading());
      });
  };
}
