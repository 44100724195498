export const VALIDATE_USER_STATE = "VALIDATE_USER_STATE";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SEND_RESET_PASSWORD = "SEND_RESET_PASSWORD";
export const LOGOUT = "LOGOUT";
export const START_AUTH_FETCH = "START_AUTH_FETCH";
export const FACEBOOK_LOGIN_FAILED = "FACEBOOK_LOGIN_FAILED";
export const RESET_FB_LOGIN_STATE = "RESET_FB_LOGIN_STATE";
export const UPDATE_CUSTOMER_INFO = "UPDATE_CUSTOMER_INFO";
export const UPDATE_CUSTOMER_VIP_INFO = "UPDATE_CUSTOMER_VIP_INFO";
