import API from "@shared/api";
import checkSEOParam from "@shared/checkSEOParam";
import errorHandler from "@shared/errorHandler";
import {formatProduct} from "@shared/productHelpers";
import {getProductWishCount} from "./wishlist";

export const LIST_START_FETCH = "LIST_START_FETCH";
export const LIST_FETCH_END = "LIST_FETCH_END";
export const LIST_FETCH_ERROR = "LIST_FETCH_ERROR";
export const GET_MORE_LIST = "GET_MORE_LIST";
export const GET_LIST = "GET_LIST";
export const LIST_REFRESH = "LIST_REFRESH";
export const NO_MORE_LIST = "NO_MORE_LIST";
export const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";
export const GET_PROMO_FETCH = "GET_PROMO_FETCH";

function checkAvailableSort(currentSort) {
  const availableSort = ["bestseller", "position", "price", "newest"];
  if (availableSort.indexOf(currentSort) === -1) {
    return "bestseller";
  }
  return currentSort;
}

/**
 * Get products by category id
 * @param {Object} config
 */
export function getProductList(config) {
  const {id, brandId, loadingType, storeName, sort, direc, filter, page, size} = config;

  // 確認網址列是否有 ?seo=1 參數
  const SEOParam = checkSEOParam();

  // 注意 seo 是小寫
  let api = `/category/products?categoryId=${id}&currentPage=${page}&size=${size}${SEOParam === "1" ? "&seo=1" : ""}`;

  if (brandId) {
    api = `/category/products/urlkey?urlkey=brand-${brandId}&currentPage=${page}&size=${size}${SEOParam === "1" ? "&seo=1" : ""}`;
  }

  if (sort === "bestseller" || sort === "newest" || sort === "position" || sort === "price") {
    api += `&sort=${sort}`;
  }

  if (direc === "asc" || direc === "desc") {
    api += `&direc=${direc}`;
  }

  if (filter && filter !== "undefined" && filter !== "null") {
    api += `&filter=${filter}`;
  }

  const request = brandId ? new API(api, storeName, "V2") : new API(api, storeName, "V4");

  return (dispatch) => {
    if (loadingType === "firstFetch") {
      dispatch({
        type: LIST_REFRESH,
      });
    } else {
      dispatch({
        type: LIST_START_FETCH,
      });
    }
    return request
      .getData()
      .then((res) => {
        // formate products
        const formatedList = res.data.products.map((product) => {
          return formatProduct(product);
        });
        const productIds = res.data.products.map((product) => product.id);

        if (loadingType === "loadMore") {
          dispatch({
            type: GET_MORE_LIST,
            products: formatedList,
            page: res.data.current_page,
            hasMore: res.data.total_page > res.data.current_page || false,
          });
        } else if (loadingType === "firstFetch") {
          dispatch({
            type: GET_LIST,
            products: formatedList,
            id,
            productCount: res.data.product_count,
            categoryDescription: res.data.category_description[0].description,
            categoryMetaDescription: res.data.category_meta_description,
            categoryMetaTitle: res.data.category_meta_title,
            categoryName: res.data.category_name,
            sortOptions: res.data.sorts,
            categoryImage: res.data.category_image_url,
            totalPage: res.data.total_page,
            defaultSort: checkAvailableSort(res.data.current_sort),
            defaultDirection: res.data.direction,
            hasMore: res.data.total_page > res.data.current_page || false,
          });
        }

        /**
         * 獲取 Wishcount
         */
        dispatch(getProductWishCount(productIds));
        dispatch({
          type: LIST_FETCH_END,
        });
      })
      .catch((error) => errorHandler(error, false));
  };
}

/**
 * Get filters by categoryId
 * @param {number} categoryId
 * @param {string} storeName
 */
export function getFilterByCateId(categoryId, storeName) {
  const api = `/category/filters?category_id=${categoryId}`;
  const request = new API(api, storeName);

  return (dispatch) => {
    return request
      .getData()
      .then((res) => {
        dispatch({
          type: GET_FILTER_OPTIONS,
          filterOptions: res.data,
        });
      })
      .catch((error) => errorHandler(error, true));
  };
}

export function getPromotionBlockData(match) {
  const id = match.params.secondLevelId || match.params.topLevelId;
  const api = `/category/promotionBlock?categoryId=${id}`;
  const request = new API(api, "default");
  return (dispatch) => {
    return request
      .getData()
      .then((res) => {
        dispatch({
          type: GET_PROMO_FETCH,
          promotionBlock: res.data,
        });
      })
      .catch((error) => errorHandler(error, true));
  };
}
