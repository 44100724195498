import * as Auth from "../actions/authActionType";

const defaultState = {
  token: "",
  username: null,
  customerId: null,
  email: null,
  addresses: [],
  telephone: null,
  forgotPasswordMsg: "",
  canGetPassword: null,
  isAuthFetching: true,
  errorMessage: null,
  isAuthChecked: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case Auth.START_AUTH_FETCH:
      return {
        ...state,
        isAuthFetching: true,
      };
    case Auth.LOGIN_SUCCESS:
      return {
        ...state,
        token: action.token,
        username: action.customer.lastname + action.customer.firstname,
        userFirstName: action.customer.firstname,
        userLastName: action.customer.lastname,
        customerId: action.customer.id,
        email: action.customer.email,
        addresses: action.customer.addresses,
        telephone: action.customer.custom_attributes[0].value,
        isAuthChecked: true,
        vipEffectiveDate: action.customer.vip_effective_date,
      };
    case Auth.LOGIN_FAILED:
      return {
        ...defaultState,
        isAuthChecked: true,
      };
    case Auth.LOGOUT:
      return {
        ...state,
        token: "",
        username: null,
        customerId: null,
        email: null,
        addresses: [],
        telephone: null,
        forgotPasswordMsg: "",
        canGetPassword: null,
        isAuthFetching: true,
        errorMessage: null,
      };
    case Auth.SEND_RESET_PASSWORD:
      return {
        ...state,
        canGetPassword: true,
        forgotPasswordMsg: "OTP 驗證碼將會在 60 秒內送至您的手機簡訊與信箱",
      };
    case Auth.FACEBOOK_LOGIN_FAILED:
      return {
        ...state,
        facebookLoginFailed: true,
        firstname: action.firstname,
        lastname: action.lastname,
        email: action.email,
        socialId: action.socialId,
      };
    case Auth.RESET_FB_LOGIN_STATE:
      return {
        ...state,
        facebookLoginFailed: false,
        firstname: null,
        lastname: null,
        email: null,
        socialId: null,
      };

    case Auth.UPDATE_CUSTOMER_INFO:
      return {
        ...state,
        username: `${action.userFirstName || state.userFirstName}${action.userLastName || state.userLastName}`,
        userFirstName: action.userFirstName || state.userFirstName,
        userLastName: action.userLastName || state.userLastName,
        email: action.email || state.email,
        telephone: action.telephone || state.telephone,
      };
    default:
      return state;
  }
}
