export const FACEBOOK_LOGIN_FAILED = "FACEBOOK_LOGIN_FAILED";
export const FACEBOOK_CLEAR_DATA = "FACEBOOK_CLEAR_DATA";
export const REGISTER_SET_DATA = "REGISTER_SET_DATA";
export const REGISTER_CLEAR_DATA = "REGISTER_CLEAR_DATA";

export function facebookLoginFailed(data) {
  const {socialId, firstname, lastname, email} = data;
  return {
    type: FACEBOOK_LOGIN_FAILED,
    socialId,
    firstname: firstname || "",
    lastname: lastname || "",
    email: email || "",
  };
}

export function cancelFB() {
  return {
    type: FACEBOOK_CLEAR_DATA,
  };
}

export function setRegisterData(data) {
  return {
    type: REGISTER_SET_DATA,
    data,
  };
}

export function clearRegisterData() {
  return {
    type: REGISTER_CLEAR_DATA,
  };
}
