import {
  START_WISHLIST_FETCHING,
  FINISH_WISHLIST_FETCHING,
  ADD_ITEM_FAIL,
  ADD_WISHLIST,
  CLEAR_WISHLIST,
  GET_WISHLIST,
  REMOVE_WISHLIST,
  REMOVE_WISHLIST_FREOM_SERVER,
  UPDATE_WISHLIST_PRODUCT_IDS,
} from "@actions/wishlist";

const initialState = {
  isFetched: false,
  byIds: [], // [1,2,3,4]
  byHash: {
    // "10": { ...productData },
  },
  productWishCount: {},
  deletedIds: [],
};

export default function reducer(state = initialState, action) {
  let temp;
  switch (action.type) {
    case START_WISHLIST_FETCHING:
      return {
        ...state,
        isFetched: false,
      };
    case FINISH_WISHLIST_FETCHING:
      return {
        ...state,
        isFetched: true,
      };
    case GET_WISHLIST:
      return {
        ...state,
        byIds: [...action.ids],
        byHash: {...action.wishlist},
      };
    case UPDATE_WISHLIST_PRODUCT_IDS:
      return {
        ...state,
        byIds: action.productIds.map((id) => parseInt(id, 10)),
      };
    case ADD_WISHLIST:
      if (state.byIds.indexOf(action.itemId) !== -1) {
        return {
          ...state,
          productWishCount: {
            ...state.productWishCount,
            [action.itemId]: (state.productWishCount[action.itemId] || 0) + 1,
          },
        };
      }

      temp = {...state.byHash[action.itemId], wishlistItemId: action.item.wishlistItemId};

      return {
        ...state,
        byIds: [...state.byIds, action.itemId],
        byHash: {
          ...state.byHash,
          [action.itemId]: {...temp},
        },
        productWishCount: {
          ...state.productWishCount,
          [action.itemId]: (state.productWishCount[action.itemId] || 0) + 1,
        },
      };
    case REMOVE_WISHLIST:
      return {
        ...state,
        byIds: state.byIds.filter((item) => {
          return item !== action.removeId; // return all the items not matching the action.id
        }),
        deletedIds: [...state.deletedIds, {id: action.removeId, isSync: false}],
        productWishCount: {
          ...state.productWishCount,
          [action.removeId]: (state.productWishCount[action.removeId] || 1) - 1,
        },
      };
    case REMOVE_WISHLIST_FREOM_SERVER:
      return {
        ...state,
        deletedIds: state.deletedIds.map((item) => {
          if (item.id === action.removeId) {
            return {
              id: action.removeId,
              isSync: true,
            };
          }
          return item;
        }),
      };
    case ADD_ITEM_FAIL:
      return {
        ...state,
        message: "Wish list add failed",
      };
    case CLEAR_WISHLIST:
      return {
        ...state,
        byIds: [],
        byHash: {},
        message: "",
      };
    case "UPDATE_PRODUCT_WISH_COUNT":
      return {
        ...state,
        productWishCount: {...state.productWishCount, ...action.productWishCount},
      };
    default:
      return state;
  }
}
