import API from "@shared/api";
import errorHandler from "@shared/errorHandler";
import {transformCount} from "@shared/numberFormatter";
import {hideLoading, showLoading} from "./global";

export const GET_PRODUCT_REVIEWS = "GET_PRODUCT_REVIEWS";
export const GET_PRODUCT_REVIEWS_SUCCESS = "GET_PRODUCT_REVIEWS_SUCCESS";
export const COMPUTE_REVIEWS_LENGTH = "COMPUTE_REVIEWS_LENGTH";
export const SEE_MORE_REVIEWS = "SEE_MORE_REVIEWS";
export const START_REVIEWS_FETCHING = "START_REVIEWS_FETCHING";
export const FINISH_REVIEWS_FETCHING = "FINISH_REVIEWS_FETCHING";

function fetchGetProductReviews() {
  return {
    type: GET_PRODUCT_REVIEWS,
  };
}

export function fetchProductReviewsSuccess({reviews, totalPage}) {
  return {
    type: GET_PRODUCT_REVIEWS_SUCCESS,
    reviews,
    totalPage,
  };
}

export function seeMoreReviews({reviews, totalPage}) {
  return {
    type: SEE_MORE_REVIEWS,
    reviews,
    totalPage,
  };
}

export function computeReviewsLength(reviewsLength) {
  return {
    type: COMPUTE_REVIEWS_LENGTH,
    reviewsLength,
  };
}

export function startReviewsFetching() {
  return {
    type: START_REVIEWS_FETCHING,
  };
}

export function finishReviewsFetching() {
  return {
    type: FINISH_REVIEWS_FETCHING,
  };
}

/**
 * getReviews
 * @param {Object} reviewParams
 * @param {String} reviewParams.sku sku
 * @param {String} reviewParams.storeName default / morningshop / proteinshop
 * @param {String} reviewParams.type fn 的用途，fetch or seeMore
 * @param {Number} reviewParams.currentPage 需要哪個 page 的東西
 */
export function getProductReviews({sku, storeName, currentPage = 1, type}) {
  return (dispatch) => {
    const apiCall = new API(`/products/reviews?sku=${sku}&size=6&currentPage=${currentPage}`, storeName);
    dispatch(fetchGetProductReviews());
    dispatch(showLoading());
    dispatch(startReviewsFetching());
    return apiCall
      .getData()
      .then((res) => {
        const {reviews, total_page: totalPage, review_count: reviewCount} = res.data;
        if (type === "fetch") {
          dispatch(fetchProductReviewsSuccess({reviews, totalPage}));
        } else {
          dispatch(seeMoreReviews({reviews, totalPage}));
        }
        dispatch(computeReviewsLength(transformCount(reviewCount)));
        dispatch(finishReviewsFetching());
        dispatch(hideLoading());
      })
      .catch((error) => errorHandler(error, true));
  };
}
