export const UPDATE_PRODUCT_LIST = "UPDATE_PRODUCT_LIST";

export function updateProductLst(formattedProductList, categoryIds, categoryName, currentId) {
  return {
    type: UPDATE_PRODUCT_LIST,
    currentProductIds: categoryIds,
    currentCategoryName: categoryName,
    currentCategoryId: currentId,
    products: formattedProductList,
  };
}
