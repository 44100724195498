import {hideLoading, showLoading, showMessage} from "@actions/global";
import API from "@shared/api";
import {s3HostWithCache} from "@shared/configs";
import errorHandler from "@shared/errorHandler";
import {checkOnSale, computeDiscount} from "@shared/productUtils";
import {getLocalWishlistProducts, removeWishlistProductsFromLocal, saveWishlistProductsToLocal} from "@storage/storage";

/**
 * Wishlist  Actions
 */
export const START_WISHLIST_FETCHING = "START_WISHLIST_FETCHING";
export const FINISH_WISHLIST_FETCHING = "FINISH_WISHLIST_FETCHING";
export const GET_WISHLIST = "GET_WISHLIST";
export const ADD_WISHLIST = "ADD_WISHLIST";
export const REMOVE_WISHLIST = "REMOVE_WISHLIST";
export const ADD_ITEM_FAIL = "ADD_ITEM_FAIL";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";
export const REMOVE_WISHLIST_FREOM_SERVER = "REMOVE_WISHLIST_FREOM_SERVER";
export const UPDATE_WISHLIST_PRODUCT_IDS = "UPDATE_WISHLIST_PRODUCT_ID";

/**
 * Action Object Creators - for update store status
 */
function getWishlist(wishlist) {
  const parsedIds = Object.keys(wishlist).map((ele) => parseInt(ele, 10));
  return {
    type: GET_WISHLIST,
    ids: [...parsedIds],
    wishlist: {...wishlist},
  };
}

function addWishlistItem(addedWishlistItem) {
  return {
    type: ADD_WISHLIST,
    item: addedWishlistItem,
    itemId: parseInt(addedWishlistItem.id, 10),
  };
}

function removeWishlistItem(removeId) {
  return {
    type: REMOVE_WISHLIST,
    removeId: parseInt(removeId, 10),
  };
}

function removeWishlistItemFromServer(removeId) {
  return {
    type: REMOVE_WISHLIST_FREOM_SERVER,
    removeId: parseInt(removeId, 10),
  };
}

function addItemFail() {
  return {
    type: ADD_ITEM_FAIL,
  };
}

export function clearWishlistAction() {
  return {
    type: CLEAR_WISHLIST,
  };
}

/**
 * Corresponding add wishlist APIs and dispath(i.e actions) wrapper
 * The productData fromat is corresponding to the prodcutCell
 * @param {object} productData - Information about the wishlist item that will be added to server and local items
 * @param {string} productData.name - The name of product
 * @param {number} productData.id - The id of product
 */
export function startWishlistFetching() {
  return {
    type: START_WISHLIST_FETCHING,
  };
}

export function finishWishlistFetching() {
  return {
    type: FINISH_WISHLIST_FETCHING,
  };
}

export function addItem(productData) {
  const addWishItem = new API("/wishlist/add", "default", "V2");
  return (dispatch, getState) => {
    const state = getState();

    if (state.auth.token) {
      const data = {
        product_id: productData.id,
      };

      return addWishItem
        .postByUserToken(state.auth.token, data)
        .then((response) => {
          // fail => product is not exist
          // message.states = false
          if (response.data[0].status) {
            // success
            const wishlistItemId = parseInt(response.data[0].wishlist_item_id, 10);

            dispatch(
              addWishlistItem({
                id: productData.id,
                wishlistItemId,
              }),
            );

            saveWishlistProductsToLocal({
              [productData.id]: {
                id: productData.id,
                wishlistItemId,
              },
            });

            dispatch(showMessage("default", `${productData.name}加入我的最愛成功`, true));
          } else {
            dispatch(addItemFail());
          }
        })
        .catch((error) => {
          errorHandler(error, true);
        });
    }

    // no token, return false
    dispatch(showMessage("default", "須先登入方可使用我的最愛", true));
    return false;
  };
}

export function deleteItems(item) {
  // pass dispatch and store.state to fetch function
  return (dispatch, getState) => {
    // for getting customer id
    const {token} = getState().auth;

    // get wishlist item id
    const {byHash} = getState().wishlist;

    let deletedWishlistItemId;

    if (Object.keys(byHash).length > 0) {
      deletedWishlistItemId = byHash[parseInt(item.id, 10)].wishlistItemId;
    } else {
      const localWishlistProducts = getLocalWishlistProducts();
      deletedWishlistItemId = localWishlistProducts[item.id].wishlistItemId;
    }

    if (token) {
      //
      dispatch(removeWishlistItem(item.id));
      removeWishlistProductsFromLocal(item.id);

      const deleteWish = new API(`/wishlist/delete?wishlist_item_id=${deletedWishlistItemId}`, "default", "V2");

      // first of all, we need to get item's wish_list_item_id
      return deleteWish
        .deleteByUserToken(token)
        .then((res) => {
          if (res.data[0].status) {
            // delete success
            dispatch(removeWishlistItemFromServer(item.id));
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    }

    dispatch(hideLoading());
    return false;
  };
}

/**
 * =============================================================
 *  The Main dispath wrapper for the wishlistScreen initialize.
 * =============================================================
 * Get wishlist from both server and local, or only from local, depends on user token
 */
export function fetchWishlist() {
  return async (dispatch, getState) => {
    // check login state
    const state = getState();
    if (state.auth.token) {
      const userInfo = {
        token: state.auth.token,
        customerId: state.auth.customerId,
      };
      dispatch(showLoading());
      const wishlistFromServer = await getWishlistFromServer(userInfo); // Array<Object>
      const productIds = Object.keys(wishlistFromServer);

      // update status
      dispatch(getWishlist(wishlistFromServer));

      // get productWishCount
      dispatch(getProductWishCount(productIds));

      saveWishlistProductsToLocal(
        productIds.reduce((wishlistProducts, currentProductId) => {
          return {
            ...wishlistProducts,
            [currentProductId]: {
              id: wishlistFromServer[currentProductId].id,
              wishlistItemId: wishlistFromServer[currentProductId].wishlistItemId,
            },
          };
        }, {}),
      );
      dispatch(hideLoading());
    }
  };
}

export function getWishlistFromLocalAction() {
  const localWishlistProductIds = getLocalWishlistProducts();

  return (dispatch) => {
    if (localWishlistProductIds) {
      dispatch({
        type: UPDATE_WISHLIST_PRODUCT_IDS,
        productIds: Object.keys(localWishlistProductIds),
      });
    }
  };
}

/**
 * Get wishlist API with token
 * @param {object} userInfo- Information about the user
 * @param {string} userInfo.token - The user token
 * @param {string} userInfo.customerId - The user customerId
 */
function getWishlistFromServer(userInfo) {
  const request = new API(`/wishlist/items`, "default", "V2");

  return request
    .getByUserToken(userInfo.token)
    .then((response) => {
      // normalize server data
      const normalizedData = response.data.reduce((map, ele) => {
        // check if product is on sale
        const fromDate = ele.product_full_info.special_from_date;
        const toDate = ele.product_full_info.special_to_date;
        const specialPrice = ele.product_full_info.special_price;
        const {price} = ele.product_full_info;
        const listPrice = ele.product_full_info.list_price;
        const {isOnSale, specialFromDate, specialToDate} = checkOnSale({fromDate, toDate});

        return {
          ...map,
          [ele.product_id]: {
            id: ele.product_id,
            wishlistIds: ele.wishlist_id,
            wishlistItemId: ele.wishlist_item_id,
            name: ele.product.name,
            sku: ele.product.sku,
            imgUrl: `${s3HostWithCache}${ele.product_full_info.small_image}`,
            is_in_stock: ele.product_full_info.quantity_and_stock_status.is_in_stock,
            stock: ele.product_full_info.quantity_and_stock_status.qty,
            originalPrice: isOnSale && specialPrice ? parseInt(price, 10) : parseInt(listPrice, 10),
            specialPrice: isOnSale && specialPrice ? parseInt(specialPrice, 10) : parseInt(price, 10),
            discount: isOnSale && specialPrice ? computeDiscount({specialPrice, originalPrice: price}) : -1,
            specialFromDate,
            specialToDate,
            isVIPLimited: ele.product_full_info.is_vip_limited === "1",
            hideVIPPrice: ele.product_full_info.hide_the_price === "1",
            type_id: ele.product_full_info.type_id,
          },
        };
      }, {});
      return normalizedData;
    })
    .catch((error) => errorHandler(error, true));
}

export function getProductWishCount(productIds) {
  const request = new API("/wishlist/fetchwishcount");
  const transformProductIdsToString = productIds.map((id) => `${id}`);

  return (dispatch) => {
    if (transformProductIdsToString.length > 0) {
      dispatch(startWishlistFetching());
      return request
        .getData({productIds: transformProductIdsToString})
        .then((response) => {
          const productWishCount = response.data.reduce((accumulator, currentValue) => {
            return {
              ...accumulator,
              [currentValue.product_id]: currentValue.wish_count,
            };
          }, {});
          // cache product wishlist count in localstorage
          dispatch({
            type: "UPDATE_PRODUCT_WISH_COUNT",
            productWishCount,
          });
        })
        .catch((error) => errorHandler(error))
        .then(() => {
          dispatch(finishWishlistFetching());
        });
    }
    return false;
  };
}
