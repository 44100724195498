const defaultState = {
  storeName: "",
  categoryName: "",
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case "SET_BREADCRUMBS":
      if (action.product) {
        return {
          ...state,
          storeName: action.storeName || state.storeName,
          categoryName: action.categoryName || state.categoryName,
          [action.product.id]: {
            id: action.product.id,
            name: action.product.name,
            taxonomy: [state.storeName || "優馬選品", action.categoryName || "", action.product.name],
            currency: "TWD",
            unit_price: action.product.unit_price,
            unit_sale_price: action.product.unit_sale_price,
            url: action.product.url,
            product_image_url: action.product.product_image_url,
          },
        };
      }
      return {
        ...state,
        storeName: action.storeName || state.storeName,
        categoryName: action.categoryName || "",
      };
    default:
      return state;
  }
}
