export const CART_FETCH_START = "CART_FETCH_START";
export const CART_FETCH_END = "CART_FETCH_END";
export const CART_FETCH_ERROR = "CART_FETCH_ERROR";
export const CART_INITIALIZE = "CART_INITIALIZE";
export const CART_INITIALIZE_END = "CART_INITIALIZE_END";
export const CART_ERROR = "CART_ERROR";
export const CREATE_CART = "CREATE_CART";
export const UPDATE_ITEM_TO_CART = "UPDATE_ITEM_TO_CART";
export const UPDATE_ITEM_TO_CART_FAILED = "UPDATE_ITEM_TO_CART_FAILED";
export const GET_CHECKOUT_DATA = "GET_CHECKOUT_DATA";
export const UPDATE_CART_ID = "UPDATE_CART_ID";
export const UPDATE_GUEST_CART = "UPDATE_GUEST_CART";
export const CART_CLEAR = "CART_CLEAR";
export const CLOSE_FREEGIFTS = "CLOSE_FREEGIFTS";
export const ERROR_FREEGIFTS = "ERROR_FREEGIFTS";
export const CART_COUNT_ERROR = "CART_COUNT_ERROR";
export const UPDATE_PARTIAL_ITEM = "UPDATE_PARTIAL_ITEM";
export const UPDATE_COUPON_CODE = "UPDATE_COUPON_CODE";
export const UPDATE_COUPON_LIST = "UPDATE_COUPON_LIST";
